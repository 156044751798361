<template>
  <div class="page-title mb-35">
    <h3>{{ title }}, {{ me.name }}!</h3>
    <p class="font-18 text-pre-wrap" v-html="subtitle"></p>
  </div>
</template>

<script>
export default {
  name: 'WelcomeMessage',
  computed: {
    me () {
      return this.$store.getters.getUserInfo
    }
  },
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: false,
      type: String
    }
  }
}
</script>

<style scoped>

</style>
