import APIHelper from '../../service/api-helper'
import CommonHelper from '../../utils/common'
import Menus from '../../config/menus'

const state = {
  contents: []
}

const mutations = {
  setContent (state, payload) {
    state.contents = payload
  },
  addOrUpdateWelcomeContent (state, payload) {
    const contentItemIndex = state.contents.findIndex(item => item.id === payload.id)
    if (contentItemIndex !== -1) {
      state.contents.splice(contentItemIndex, 1, payload)
    } else {
      state.contents.push(payload)
    }
  }
}

const actions = {
  async getWelcomeContentsFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/content-item', { 'app-module': Menus.welcome.key })
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateWelcomeContentItem ({ commit, state }, payload) {
    let url
    if (payload.id) {
      url = CommonHelper.makeApiUrl(`v1/content-item/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/content-item')
    }
    try {
      let response
      if (payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      const data = response.data
      if (data.success) {
        commit('addOrUpdateWelcomeContent', data)
      }
      return response
    } catch (e) {
      return e
    }
  }
}

const getters = {
  getWelcomeContents (state) {
    return state.contents
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
