export const AdminRoles = [
  'Admin', 'SystemAdmin'
]
export const ObserverRoles = [
  'Observer'
]

export const ContentTypes = {
  MULTI_WIDGET: 'MWT',
  TABLE: 'TBL',
  CHART: 'CRT',
  CARD: 'CRD',
  MINI_CARD: 'MCD',
  GREETING: 'GTS',
  KEY_SERVICE: 'KSV',
  KEY_STATISTICS: 'KST',
  FARMER_STORIES: 'FS',
  RESOURCE: 'RSC',
  RESOURCE_MINI_CARD: 'RMC',
  CARD_DECK: 'CDK',
  WIDGET_DECK: 'WDK',
  CRITERIA: 'CTA',
  KEY_VALUE: 'KVL',
  FILE: 'FLE'
}

export const MapLegends = {
  region: 'Region',
  farmerGroup: 'Farmer Groups/Labels',
  farmerSubGroup: 'Farmer Subgroups',
  purchasePoint: 'Purchase Point',
  exportMill: 'Export Mill',
  portOfOrigin: 'Port of Origin',
  portOfDestination: 'Port of Destination'
}
