import CommonUtils from '../utils/common'
import APIHelper from './api-helper'
import ServerConfig from '../config/server'

export default class PaginateApiCaller {
  constructor ({ path, limit = 25, params = {} }) {
    this.limit = limit
    this.offset = 0 // For the first time
    params = {
      ...params,
      ...{
        limit: this.limit,
        offset: this.offset
      }
    }
    this.apiUrl = CommonUtils.makeApiUrl(path, params)
    this.data = []
    this.errors = []
  }

  async get () {
    const response = await APIHelper.getData(this.apiUrl)
    const data = response.data
    if (data.success) {
      this.data = [...this.data, ...data.results]
      if (data.next !== null) {
        this.apiUrl = ServerConfig.serverUrl + data.next
        return this.get()
      }
    } else {
      this.errors.push(data)
    }
  }

  response () {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await this.get()
      if (this.errors.length > 0) {
        reject(this.errors)
      }
      resolve(this.data)
    })
  }
}
