export default {
  name: 'FormMixin',
  data () {
    return {
      isSaveButtonActive: true,
      saveButtonTxt: 'Submit'
    }
  },
  computed: {},
  filters: {
    ordinal: function (value) {
      if (!value) return ''
      const j = value % 10
      const k = value % 100
      if (j === 1 && k !== 11) {
        return value + 'st'
      }
      if (j === 2 && k !== 12) {
        return value + 'nd'
      }
      if (j === 3 && k !== 13) {
        return value + 'rd'
      }
      return value + 'th'
    }
  },
  methods: {
    resetImageFileUploader () {
      // It reset image or file uploader if same file selected again
      this.$refs.imageFileUploader.value = ''
    },
    removeFromChildForm (objects, index, removeFrom = 'api', storeObject = null) {
      try {
        if (index > -1 && objects.length > index) {
          const object = objects[index]
          if (removeFrom === 'api' && object.id) {
            const vm = this
            this.$swal.fire({
              title: 'Are you sure?',
              text: 'You can not undo this operation.',
              icon: 'warning',
              showCancelButton: true,
              allowOutsideClick: true,
              confirmButtonColor: '#006330',
              confirmButtonText: 'Okay!'
            }).then(async (result) => {
              if (result.value) {
                await vm.removeFromServer(objects, object, index)
                if (storeObject) {
                  _.remove(storeObject, o => o.id === object.id)
                }
              }
            })
          } else {
            objects.splice(index, 1)
          }
        }
      } catch (e) {
        this.errorLog(e)
      }
    },
    async removeFromServer (allObjects, object, index) {
      try {
        const response = await this.$store.dispatch('deleteContentItem', object.id)
        const data = response.data
        if (data.success) {
          allObjects.splice(index, 1)
          this.$notify({
            title: '',
            text: 'Item deleted successfully.',
            type: 'success', // 'warn', 'error', 'success'
            duration: 5000
          })
        }
      } catch (e) {
        this.errorLog(e)
        this.$notify({
          title: '',
          text: e.message,
          type: 'error', // 'warn', 'error', 'success'
          duration: 5000
        })
      }
    },
    clearIcon (object, index = null) {
      if (index === null) {
        object.icon.previewUrl = null
        object.icon.file = null
        object.icon.name = null
        object.icon.tsync_id = null
        object.icon.relative_url = null
        return true
      }
      object[index].icon.previewUrl = null
      object[index].icon.file = null
      object[index].icon.name = null
      object[index].icon.tsync_id = null
      object[index].icon.relative_url = null

      this.$forceUpdate()
    },
    clearCoverImage (object, index = null) {
      if (index === null) {
        object.photo.previewUrl = null
        object.photo.file = null
        object.photo.name = null
        object.photo.relative_url = null
        return true
      }
      object[index].photo.previewUrl = null
      object[index].photo.file = null
      object[index].photo.name = null
      object[index].photo.relative_url = null
    },
    clearFile (object, index = null) {
      if (index === null) {
        object.file.file = null
        object.file.name = null
        object.file.tsync_id = null
        return true
      }
      object[index].file.file = null
      object[index].file.name = null
      object[index].file.tsync_id = null
    }
  }
}
