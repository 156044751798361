<template>
  <nav
    class="navbar navbar-light navbar-expand-xl sticky-top navigation-clean"
  >
    <div class="container">
      <router-link
        v-show="isAdminUser"
        :to="{name: 'AdminWelcomeView'}"
        class="navbar-brand"
      >
        <img :src="logo" alt="Logo"/>
      </router-link>
      <router-link
        v-show="!isAdminUser"
        :to="{name: 'WelcomeView'}"
        class="navbar-brand"
      >
        <img :src="logo" alt="Logo"/>
      </router-link>
      <button
        class="navbar-toggler"
        data-target="#navcol-1"
        data-toggle="collapse"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navcol-1" class="collapse navbar-collapse">
        <ul v-if="isAdminUser && isAdminPath()"
            class="nav navbar-nav d-xl-flex mr-auto justify-content-xl-center align-items-xl-center">
          <li class="nav-item" role="presentation">
            <router-link
              :to="{name:'AdminWelcomeView'}"
              class="nav-link"
            >
               <span>
                                <span class="img">
                                    <img
                                      alt="Icon"
                                      src="@/assets/img/icons/home.svg"
                                    />
                                </span>
                                <span class="txt"> Welcome </span>
                              </span>
            </router-link>
          </li>
          <li class="nav-item" role="presentation">
            <router-link
              :to="{name: 'AdminEnablingFarmersView'}"
              class="nav-link text-center"
            >
               <span>
                                    <span class="img">
                                        <img
                                          alt="Icon"
                                          src="@/assets/img/icons/rocket.svg"
                                        />
                                    </span>
                                    <span class="txt">
                                        Enabling <br/>
                                        farmers
                                    </span>
                                </span>
            </router-link>
          </li>
          <li class="nav-item" role="presentation">
            <router-link
              :to="{name: 'AdminInclusiveSustainabilityView'}"
              class="nav-link text-center"
            >
               <span>
                                <span class="img">
                                    <img
                                      alt="Icon"
                                      src="@/assets/img/icons/inclusive.svg"
                                    />
                                   </span>
                                   <span class="txt">
                                    Inclusive <br/>sustainability
                                </span>
                                </span>
            </router-link>
          </li>
          <li class="nav-item" role="presentation">
            <router-link
              :to="{name: 'AdminTraceabilityView'}"
              class="nav-link text-center"
            >
                 <span>
                              <span class="img">
                                  <img
                                    alt="Icon"
                                    src="@/assets/img/icons/traceability.svg"
                                  />
                                 </span>
                                 <span class="txt">
                                    Traceability<br/><br/>
                                </span>
                                </span>
            </router-link>
          </li>
          <li class="nav-item" role="presentation">
            <router-link
              :to="{name: 'AdminRegionsFarmersGroupsView'}"
              class="nav-link text-center"
            >
                 <span>
                              <span class="img">
                                  <img
                                    alt="Icon"
                                    src="@/assets/img/icons/regions.svg"
                                  />
                                 </span>
                                 <span class="txt">
                                    Regions &amp; <br/>farmer groups/labels
                                </span>
                                </span>
            </router-link>
          </li>
          <li class="nav-item" role="presentation">
            <router-link
              :to="{name: 'AdminResourcesView'}"
              class="nav-link text-center"
            >
                      <span>
                              <span class="img">
                                  <img
                                    alt="Icon"
                                    src="@/assets/img/icons/resources.svg"
                                  />
                                 </span>
                                 <span class="txt">

                                    Resources
                                </span>
                                </span>
            </router-link>
          </li>
          <li class="nav-item" role="presentation"></li>
        </ul>
        <ul v-else
            class="nav navbar-nav d-xl-flex mr-auto justify-content-xl-center align-items-xl-center">
          <li class="nav-item" role="presentation">
            <router-link
              :to="{name: 'WelcomeView'}"
              class="nav-link"
            >
              <span>
                                <span class="img">
                                    <img
                                      alt="Icon"
                                      src="@/assets/img/icons/home.svg"
                                    />
                                </span>
                                <span class="txt"> Welcome </span>
                              </span>
            </router-link>
          </li>
          <li class="nav-item" role="presentation">
            <router-link
              :to="{name: 'EnablingFarmerView'}"
              class="nav-link text-center"
            >
               <span>
                                    <span class="img">
                                        <img
                                          alt="Icon"
                                          src="@/assets/img/icons/rocket.svg"
                                        />
                                    </span>
                                    <span class="txt">
                                        Enabling <br/>
                                        farmers
                                    </span>
                                </span>
            </router-link>
          </li>
          <li class="nav-item" role="presentation">
            <router-link
              :to="{name: 'InclusiveSustainabilityView'}"
              class="nav-link text-center"
            >
               <span>
                                <span class="img">
                                    <img
                                      alt="Icon"
                                      src="@/assets/img/icons/inclusive.svg"
                                    />
                                   </span>
                                   <span class="txt">
                                    Inclusive <br/>sustainability
                                </span>
                                </span>
            </router-link>
          </li>
          <li class="nav-item" role="presentation">
            <router-link
              :to="{name: 'TraceabilityView'}"
              class="nav-link text-center"
            >
                <span>
                              <span class="img">
                                  <img
                                    alt="Icon"
                                    src="@/assets/img/icons/traceability.svg"
                                  />
                                 </span>
                                 <span class="txt">
                                    Traceability<br/><br/>
                                </span>
                                </span>
            </router-link>
          </li>
          <li class="nav-item" role="presentation">
            <router-link
              :to="{name: 'RegionsFarmersGroupsView'}"
              class="nav-link text-center"
            >
                 <span>
                              <span class="img">
                                  <img
                                    alt="Icon"
                                    src="@/assets/img/icons/regions.svg"
                                  />
                                 </span>
                                 <span class="txt">
                                    Regions &amp; <br/>farmer groups/labels
                                </span>
                                </span>
            </router-link>
          </li>
          <li class="nav-item" role="presentation">
            <router-link
              :to="{name: 'ResourcesView'}"
              class="nav-link text-center"
            >
                     <span>
                              <span class="img">
                                  <img
                                    alt="Icon"
                                    src="@/assets/img/icons/resources.svg"
                                  />
                                 </span>
                                 <span class="txt">

                                    Resources
                                </span>
                                </span>
            </router-link>
          </li>
          <li class="nav-item" role="presentation"></li>
        </ul>
        <ul class="nav navbar-nav ml-auto">
          <li class="nav-item dropdown">
            <a
              :title="me.name"
              aria-expanded="true"
              class="dropdown-toggle nav-link"
              data-toggle="dropdown" href="#">
              <img v-show="me.user_photo" :src="me.user_photo|makeAbsUrl" class="rounded-circle"/>
              <div class="navbar-username">{{ me.name |textTruncate(12) }}</div>
            </a>
            <div class="dropdown-menu" role="menu">
              <a
                class="dropdown-item"
                href="mailto:bloom@nkg.coffee?subject=Feedback"
                role="presentation">
                <img class="image-icon-sm" src="../../assets/img/icons/get-in-touch.svg"/>&nbsp;Get in touch<br/>
              </a>
              <a
                v-show="isAdminPath() && isAdminUser"
                class="dropdown-item"
                href="#"
                role="presentation"
                @click="switchToPreview()">
                <img class="image-icon-sm" src="../../assets/img/icons/config.png"/>&nbsp;Switch to Preview<br/>
              </a>
              <a
                v-show="!isAdminPath() && isAdminUser"
                class="dropdown-item"
                href="#"
                role="presentation"
                @click="switchToConfig()">
                <img class="image-icon-sm" src="../../assets/img/icons/config.png"/>Switch to Config<br/>
              </a>
              <router-link
                :to="{name: 'ChangePassword'}"
                class="dropdown-item"
                role="presentation"
              >
                <span class="img">
                  <img
                    alt="Icon"
                    class="image-icon-sm" src="@/assets/img/icons/cog-lg.png"
                  />
                </span>
                <span class="txt">
                  Change password
                </span>
              </router-link>
              <a
                class="dropdown-item"
                href=""
                role="presentation"
                @click="handleLogout">
                <img class="image-icon-sm" src="../../assets/img/icons/logout.png"/>&nbsp;Logout<br/>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import logo from '../../assets/img/logo.png'
import logoutIcon from '../../assets/img/Logout.png'
import adminIcon from '../../assets/img/admin.png'
import configIcon from '../../assets/img/Config.png'
import userIcon from '../../assets/img/team.jpg'
import UtilityMixin from '../../mixins/utility-mixin'

export default {
  name: 'Navbar',
  mixins: [UtilityMixin],
  data () {
    return {
      logo: logo,
      logoutIcon: logoutIcon,
      adminIcon: adminIcon,
      configIcon: configIcon,
      userIcon: userIcon
    }
  },
  computed: {
    me () {
      return this.$store.getters.getUserInfo
    }
  },
  methods: {
    handleLogout () {
      this.$store.dispatch('handleLogout')
    },
    switchToPreview () {
      const newPath = _.replace(this.$route.path, '/admin/', '/')
      window.open(newPath, '_blank')
    },
    switchToConfig () {
      let newPath = ''
      if (!_.includes(this.$route.path, '/admin/')) {
        newPath = '/admin' + this.$route.path
        window.open(newPath, '_self')
      }
    },
    isAdminPath () {
      return _.includes(this.$route.path, '/admin/')
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-link img:hover {
  filter: invert(57%) sepia(94%) saturate(370%) hue-rotate(45deg) brightness(83%) contrast(97%);
}

</style>
