<template>
  <welcome-dashboard/>
</template>

<script>
import WelcomeDashboard from '../components/user/welcome/WelcomeDashboard'

export default {
  name: 'Welcome',
  components: { WelcomeDashboard }
}
</script>

<style scoped>

</style>
