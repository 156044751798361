import Vue from 'vue'
import VueRouter from 'vue-router'
import middlewares from './middlewares'
import Welcome from '../views/Welcome.vue'
import Login from '../components/auth/Login'
import ChangePassword from '@/components/auth/ChangePassword'
import ResetPassword from '@/components/auth/ResetPassword'
import ResetPasswordConfirm from '@/components/auth/ResetPasswordConfirm'

Vue.use(VueRouter)

export default [
  {
    path: '/welcome',
    name: 'WelcomeView',
    component: Welcome,
    meta: {
      middleware: [
        middlewares.auth
      ]
    }
  },
  {
    path: '/admin',
    name: 'AdminPanel',
    component: () => import(/* webpackChunkName: "admin-panel" */ '../views/admin/AdminPanel.vue'),
    meta: {
      middleware: [
        middlewares.auth, middlewares.isAdmin
      ]
    },
    children: [
      {
        path: '/admin/welcome',
        name: 'AdminWelcomeView',
        component: () => import(/* webpackChunkName: "admin-welcome" */ '../views/admin/WelcomeView.vue'),
        meta: {
          middleware: [
            middlewares.auth, middlewares.isAdmin
          ]
        }
      },
      {
        path: '/admin/enabling-farmers',
        name: 'AdminEnablingFarmersView',
        component: () => import(/* webpackChunkName: "admin-enabling-farmers" */ '../views/admin/EnablingFarmersView.vue'),
        meta: {
          middleware: [
            middlewares.auth, middlewares.isAdmin
          ]
        }
      },
      {
        path: '/admin/inclusive-sustainability',
        name: 'AdminInclusiveSustainabilityView',
        component: () => import(/* webpackChunkName: "admin-inclusive-sustainability" */ '../views/admin/InclusiveSustainabilityView.vue'),
        meta: {
          middleware: [
            middlewares.auth, middlewares.isAdmin
          ]
        }
      },
      {
        path: '/admin/traceability',
        name: 'AdminTraceabilityView',
        component: () => import(/* webpackChunkName: "admin-traceability" */ '../views/admin/TraceabilityView.vue'),
        meta: {
          middleware: [
            middlewares.auth, middlewares.isAdmin
          ]
        }
      },
      {
        path: '/admin/regions-farmers-groups',
        name: 'AdminRegionsFarmersGroupsView',
        component: () => import(/* webpackChunkName: "admin-regions-farmers-groups" */ '../views/admin/RegionsFarmersGroupsView.vue'),
        meta: {
          middleware: [
            middlewares.auth, middlewares.isAdmin
          ]
        }
      },
      {
        path: '/admin/region/:id',
        name: 'RegionEditView',
        meta: {
          middleware: [
            middlewares.auth, middlewares.isAdmin
          ]
        },
        component: () => import(/* webpackChunkName: "region-edit" */ '../components/admin/regions-farmers-groups/edit-view/RegionEditView.vue')
      },
      {
        path: '/admin/group/:id',
        name: 'GroupEditView',
        meta: {
          middleware: [
            middlewares.auth, middlewares.isAdmin
          ]
        },
        component: () => import(/* webpackChunkName: "group-edit" */ '../components/admin/regions-farmers-groups/edit-view/GroupEditView.vue')
      },
      {
        path: '/admin/sub-group/:id',
        name: 'SubGroupEditView',
        meta: {
          middleware: [
            middlewares.auth, middlewares.isAdmin
          ]
        },
        component: () => import(/* webpackChunkName: "sub-group-edit" */ '../components/admin/regions-farmers-groups/edit-view/SubGroupEditView.vue')
      },
      {
        path: '/admin/resources',
        name: 'AdminResourcesView',
        component: () => import(/* webpackChunkName: "admin-resources" */ '../views/admin/ResourcesView.vue'),
        meta: {
          middleware: [
            middlewares.auth, middlewares.isAdmin
          ]
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      middleware: [
        middlewares.notAuth
      ]
    }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      middleware: [
        middlewares.auth
      ]
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      middleware: [
        middlewares.public
      ]
    }
  },
  {
    path: '/password/reset/:uid/:token',
    name: 'ResetPasswordConfirm',
    component: ResetPasswordConfirm,
    meta: {
      middleware: [
        middlewares.public
      ]
    }
  },
  {
    path: '/enabling-farmers',
    name: 'EnablingFarmerView',
    meta: {
      middleware: [
        middlewares.auth
      ]
    },
    component: () => import(/* webpackChunkName: "enabling-farmer" */ '../views/EnablingFarmerView.vue')
  },
  {
    path: '/inclusive-sustainability',
    name: 'InclusiveSustainabilityView',
    meta: {
      middleware: [
        middlewares.auth
      ]
    },
    component: () => import(/* webpackChunkName: "inclusive-sustainability" */ '../views/InclusiveSustainabilityView.vue')
  },
  {
    path: '/traceability',
    name: 'TraceabilityView',
    meta: {
      middleware: [
        middlewares.auth
      ]
    },
    component: () => import(/* webpackChunkName: "traceability" */ '../views/TraceabilityView.vue')
  },
  {
    path: '/traceability/:id',
    name: 'ContractDetail',
    meta: {
      middleware: [
        middlewares.auth
      ]
    },
    component: () => import(/* webpackChunkName: "traceability-detail" */ '../components/user/traceability/ContractDetail.vue')
  },
  {
    path: '/regions-farmers-groups/',
    name: 'RegionsFarmersGroupsView',
    meta: {
      middleware: [
        middlewares.auth
      ]
    },
    component: () => import(/* webpackChunkName: "regions-farmers-groups" */ '../views/RegionsFarmersGroupsView.vue')
  },
  {
    path: '/farmers-region-group/detail/:id/:type/',
    name: 'RegionFarmerGroupsDetail',
    meta: {
      middleware: [
        middlewares.auth
      ]
    },
    component: () => import(/* webpackChunkName: "region-farmer-group-detail" */ '../components/admin/regions-farmers-groups/RegionFarmerGroupsDetail.vue')
  },
  {
    path: '/admin/farmers-region-group/detail/:id/',
    name: 'AdminRegionFarmerGroupsDetail',
    meta: {
      middleware: [
        middlewares.auth, middlewares.isAdmin
      ]
    },
    component: () => import(/* webpackChunkName: "admin-region-farmer-group-detail" */ '../components/admin/regions-farmers-groups/RegionFarmerGroupsDetail.vue')
  },
  {
    path: '/resources',
    name: 'ResourcesView',
    meta: {
      middleware: [
        middlewares.auth
      ]
    },
    component: () => import(/* webpackChunkName: "resources" */ '../views/ResourcesView.vue')
  },
  {
    path: '/no-access',
    name: 'NoAccessView',
    meta: {
      middleware: [
        middlewares.auth
      ]
    },
    component: () => import(/* webpackChunkName: "no-access" */ '../views/NoAccess.vue')
  }
]
