import APIHelper from '../../service/api-helper'
import CommonHelper from '../../utils/common'
import Menus from '../../config/menus'
import { ContentTypes } from '@/config/constants'
import _ from 'lodash'

const state = {
  enablingFarmersContents: []
}

const mutations = {
  setContent (state, payload) {
    state.enablingFarmersContents = payload
  },
  addOrUpdateEnablingFarmersContent (state, payload) {
    const contentItemIndex = state.enablingFarmersContents.findIndex(item => item.id === payload.id)
    if (contentItemIndex !== -1) {
      state.enablingFarmersContents.splice(contentItemIndex, 1, payload)
    } else {
      state.enablingFarmersContents.push(payload)
    }
  }
}

const actions = {
  async getEnablingFarmerContentsFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/content-item', { 'app-module': Menus.enablingFarmers.key })
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateEnablingFarmersContent ({ commit, state }, payload) {
    let url
    if (payload.id) {
      url = CommonHelper.makeApiUrl(`v1/content-item/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/content-item')
    }
    try {
      let response
      if (payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      const data = response.data
      if (data.success) {
        commit('addOrUpdateEnablingFarmersContent', data)
      }
      return response
    } catch (e) {
      return e
    }
  }
}

const getters = {
  getEnablingFarmerContents (state) {
    return state.enablingFarmersContents
  },
  getEnablingFarmersAllCountryGreetingContent (state) {
    const _content = state.enablingFarmersContents.find((item) => {
      return item.parent === null && item.content_type === ContentTypes.GREETING && item.country === null
    })
    return _content || {}
  },
  getEnablingFarmersAllCountryKeyServicesGreetings (state) {
    const keyServiceContentType = state.enablingFarmersContents.find(
      item => item.content_type === ContentTypes.KEY_SERVICE)
    if (!keyServiceContentType) return []

    const _content = state.enablingFarmersContents.find((item) => {
      return item.parent === keyServiceContentType.id &&
        item.content_type === ContentTypes.GREETING && item.country === null
    })
    return _content || {}
  },
  getEnablingFarmersAllCountryKeyServices (state) {
    const keyServiceContentType = state.enablingFarmersContents.find(
      item => item.content_type === ContentTypes.KEY_SERVICE)
    if (!keyServiceContentType) return []

    let _contents = state.enablingFarmersContents.filter((item) => {
      return item.parent === keyServiceContentType.id &&
        item.content_type === ContentTypes.MINI_CARD && item.country === null
    })
    _contents = _.sortBy(_contents, function (item) {
      return parseInt(item.order, 10)
    })
    return _contents || []
  },
  getEnablingFarmersCountryGreetingContent: state => countryName => {
    const _content = state.enablingFarmersContents.find((item) => {
      return item.parent === null && item.content_type === ContentTypes.GREETING && item.country !== null && item.country.name === countryName
    })
    return _content || {}
  },
  getEnablingFarmersCountryKeyServicesGreetings: state => countryName => {
    const keyServiceContentType = state.enablingFarmersContents.find(
      item => item.content_type === ContentTypes.KEY_SERVICE)
    if (!keyServiceContentType) return {}

    const _content = state.enablingFarmersContents.find((item) => {
      return item.parent === keyServiceContentType.id &&
        item.content_type === ContentTypes.GREETING && item.country !== null && item.country.name === countryName
    })
    return _content || {}
  },
  getEnablingFarmersCountryKeyStatistics: state => countryName => {
    const keyStatisticsContentType = state.enablingFarmersContents.find(
      item => item.content_type === ContentTypes.KEY_STATISTICS)
    if (!keyStatisticsContentType) return []

    let _content = state.enablingFarmersContents.filter((item) => {
      return item.parent === keyStatisticsContentType.id &&
        item.content_type === ContentTypes.MINI_CARD &&
        item.country !== null && item.country.name === countryName
    })
    _content = _.sortBy(_content, function (item) {
      return parseInt(item.order, 10)
    })
    return _content || []
  },
  getEnablingFarmersAllCountryKeyStatistics (state) {
    const keyStatisticsContentType = state.enablingFarmersContents.find(
      item => item.content_type === ContentTypes.KEY_STATISTICS)
    if (!keyStatisticsContentType) return []

    let _content = state.enablingFarmersContents.filter((item) => {
      return item.parent === keyStatisticsContentType.id &&
        item.content_type === ContentTypes.MINI_CARD && item.country == null
    })
    _content = _.sortBy(_content, function (item) {
      return parseInt(item.order, 10)
    })
    return _content || []
  },
  getEnablingFarmerCountryKeyServicesByYear: state => (countryName, year) => {
    const keyServiceContentType = state.enablingFarmersContents.find(
      item => item.content_type === ContentTypes.KEY_SERVICE)
    if (!keyServiceContentType) return []
    let _contents = []
    if (year === 'all') {
      _contents = state.enablingFarmersContents.filter((item) => {
        return item.parent === keyServiceContentType.id &&
          item.content_type === ContentTypes.MINI_CARD &&
          item.country !== null && item.country.name === countryName
      })
    } else {
      _contents = state.enablingFarmersContents.filter((item) => {
        return item.parent === keyServiceContentType.id &&
          item.content_type === ContentTypes.MINI_CARD &&
          item.country !== null && item.country.name === countryName && item.year === year
      })
    }
    _contents = _.orderBy(_contents, [function (o) {
      return Number(o.year)
    }, 'order'], ['desc', 'asc'])
    return _contents || []
  },
  getEnablingFarmerCountryResourcesByYear: state => (countryName) => {
    let _contents = []

    _contents = state.enablingFarmersContents.filter((item) => {
      return item.content_type === ContentTypes.RESOURCE_MINI_CARD &&
        item.country !== null && item.country.name === countryName
    })
    _contents = _.orderBy(_contents, ['order'], ['asc'])

    return _contents || []
  },
  getEnablingFarmerCountryFarmerStories: state => (countryName) => {
    let _contents = []

    _contents = state.enablingFarmersContents.filter((item) => {
      return item.content_type === ContentTypes.FARMER_STORIES &&
        item.country !== null && item.country.name === countryName
    })

    return _contents || []
  },
  getEnablingFarmerCountryResourceTitleByYear: state => (countryName) => {
    const resourceContentType = state.enablingFarmersContents.find(
      item => item.content_type === ContentTypes.RESOURCE)
    if (!resourceContentType) return []
    const _contents = state.enablingFarmersContents.find((item) => {
      return item.parent === resourceContentType.id &&
        item.content_type === ContentTypes.GREETING &&
        item.country !== null && item.country.name === countryName
    })
    return _contents || {}
  },
  getEnablingFarmersAllCountryChartContent (state) {
    const _content = state.enablingFarmersContents.find((item) => {
      return item.content_type === ContentTypes.CHART && item.country === null
    })
    return _content || {}
  },
  getEnablingFarmersCountryChartContent: state => countryName => {
    const _content = state.enablingFarmersContents.find((item) => {
      return item.content_type === ContentTypes.CHART && item.country !== null && item.country.name === countryName
    })
    return _content || {}
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
