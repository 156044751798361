import APIHelper from '../../service/api-helper'
import CommonHelper from '../../utils/common'
import Menus from '../../config/menus'
import { ContentTypes } from '@/config/constants'
import _ from 'lodash'

const state = {
  sustainabilityContents: []
}

const mutations = {
  setSustainabilityContent (state, payload) {
    state.sustainabilityContents = payload
  },
  addOrUpdateSustainabilityContent (state, payload) {
    const contentItemIndex = state.sustainabilityContents.findIndex(item => item.id === payload.id)
    if (contentItemIndex !== -1) {
      state.sustainabilityContents.splice(contentItemIndex, 1, payload)
    } else {
      state.sustainabilityContents.push(payload)
    }
  }
}

const actions = {
  async getSustainabilityContentsFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/content-item', { 'app-module': Menus.inclusiveSustainability.key })
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setSustainabilityContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateSustainabilityContentItem ({ commit, state }, payload) {
    let url
    if (payload.id) {
      url = CommonHelper.makeApiUrl(`v1/content-item/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/content-item')
    }
    try {
      let response
      if (payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      const data = response.data
      if (data.success) {
        commit('addOrUpdateSustainabilityContent', data)
      }
      return response
    } catch (e) {
      return e
    }
  }
}

const getters = {
  getSustainabilityAllCountryGreetings (state) {
    const _content = state.sustainabilityContents.find((item) => {
      return item.parent === null && item.content_type === ContentTypes.GREETING && item.country === null
    })
    return _content || {}
  },
  getSustainabilityAllCountryCardDecks (state) {
    const cardDeckContentType = state.sustainabilityContents.find((item) => {
      return item.parent === null && item.content_type === ContentTypes.CARD_DECK && item.country === null
    })
    if (!cardDeckContentType) return []
    let _contents = state.sustainabilityContents.filter((item) => {
      return item.parent === cardDeckContentType.id && item.country === null &&
        item.content_type === ContentTypes.CARD
    })
    _contents = _.sortBy(_contents, function (item) {
      return parseInt(item.order, 10)
    })
    return _contents || []
  },
  getSustainabilityAllCountryCriteriums (state) {
    const cardDeckContentType = state.sustainabilityContents.find((item) => {
      return item.parent === null && item.content_type === ContentTypes.CRITERIA && item.country === null
    })
    if (!cardDeckContentType) return []
    let _contents = state.sustainabilityContents.filter((item) => {
      return item.parent === cardDeckContentType.id && item.country === null &&
        item.content_type === ContentTypes.CARD
    })
    _contents = _.sortBy(_contents, function (item) {
      return parseInt(item.order, 10)
    })
    return _contents || []
  },
  getSustainabilityCountryGreetings: state => countryName => {
    const _content = state.sustainabilityContents.find((item) => {
      return item.parent === null &&
        item.content_type === ContentTypes.GREETING &&
        item.country !== null &&
        item.country.name === countryName
    })
    return _content || {}
  },
  getSustainabilityCountryKeyServiceGreetings: state => countryName => {
    const keyServiceContentType = state.sustainabilityContents.find((item) => {
      return item.parent === null &&
        item.content_type === ContentTypes.KEY_SERVICE &&
        item.country === null
    })
    if (!keyServiceContentType) return {}
    const _content = state.sustainabilityContents.find((item) => {
      return item.parent !== null && item.parent === keyServiceContentType.id &&
        item.content_type === ContentTypes.GREETING &&
        item.country !== null && item.country.name === countryName
    })
    return _content || {}
  },
  getSustainabilityCountryYearWidgetDecks: state => (countryName, year) => {
    const widgetDeckContentType = state.sustainabilityContents.find((item) => {
      return item.parent === null &&
        item.content_type === ContentTypes.WIDGET_DECK &&
        item.country === null
    })
    if (!widgetDeckContentType) return []
    let _contents = state.sustainabilityContents.filter((item) => {
      return item.parent !== null &&
        item.parent === widgetDeckContentType.id &&
        item.content_type === ContentTypes.KEY_VALUE &&
        item.country !== null &&
        item.country.name === countryName &&
        item.year === year
    })
    _contents = _.sortBy(_contents, function (item) {
      return parseInt(item.order, 10)
    })
    return _contents || []
  },
  getSustainabilityCountryCardDecksByYear: state => (countryName, year) => {
    const cardDeckContentType = state.sustainabilityContents.find(
      item => item.content_type === ContentTypes.CARD_DECK)
    if (!cardDeckContentType) return []
    let _contents = []

    if (year === 'all') {
      _contents = state.sustainabilityContents.filter((item) => {
        return item.parent === cardDeckContentType.id &&
          item.content_type === ContentTypes.MINI_CARD &&
          item.country !== null && item.country.name === countryName
      })
    } else {
      _contents = state.sustainabilityContents.filter((item) => {
        return item.parent === cardDeckContentType.id &&
          item.content_type === ContentTypes.MINI_CARD &&
          item.country !== null && item.country.name === countryName && item.year === year
      })
    }
    _contents = _.orderBy(_contents, [function (o) {
      return Number(o.year)
    }, 'order'], ['desc', 'asc'])
    return _contents || []
  },
  getSustainabilityCountryResources: state => (countryName) => {
    let _contents = []
    _contents = state.sustainabilityContents.filter((item) => {
      return item.content_type === ContentTypes.RESOURCE_MINI_CARD &&
        item.country !== null && item.country.name === countryName
    })
    _contents = _.sortBy(_contents, function (item) {
      return parseInt(item.order, 10)
    })
    return _contents || []
  },
  getSustainabilityCountryResourceTitle: state => (countryName) => {
    const resourceContentType = state.sustainabilityContents.find(
      item => item.content_type === ContentTypes.RESOURCE)
    if (!resourceContentType) return []
    const _contents = state.sustainabilityContents.find((item) => {
      return item.parent === resourceContentType.id &&
        item.content_type === ContentTypes.GREETING &&
        item.country !== null && item.country.name === countryName
    })
    return _contents || {}
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
