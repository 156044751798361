import APIHelper from '../../service/api-helper'
import CommonHelper from '../../utils/common'
import _ from 'lodash'

const state = {
  regionFgSg: [],
  regionFGDetailContractsContents: []
}

const mutations = {
  setRegionFgSg (state, payload) {
    state.regionFgSg = payload
  },
  setRegionFGDetailContractsContents (state, payload) {
    state.regionFGDetailContractsContents = payload
  }
}

const actions = {
  async getUserRegionFgSg ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'countryCode') || null) {
        url = CommonHelper.makeApiUrl('region-fg-key-stats')
        response = await APIHelper.getData(url + `?search=1&country_code=${payload.countryCode}`)
      } else {
        url = CommonHelper.makeApiUrl('region-fg-key-stats')
        response = await APIHelper.getData(url)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getUserContractsForRegionGroupsFromServer ({ commit, state }, payload) {
    let url, response
    if (_.get(payload, 'region') || null) {
      url = CommonHelper.makeApiUrl('v1/contract-region-groups')
      response = await APIHelper.getData(url + `?search=1&region:region_meta:id=${payload.region}`)
    } else if (_.get(payload, 'group') || null) {
      url = CommonHelper.makeApiUrl('v1/contract-region-groups')
      response = await APIHelper.getData(url + `?search=1&group:group_meta:id=${payload.group}`)
    } else if (_.get(payload, 'sub_group') || null) {
      url = CommonHelper.makeApiUrl('v1/contract-region-groups')
      response = await APIHelper.getData(url + `?search=1&sub_group:sub_group_meta:id=${payload.sub_group}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/contract-region-groups')
      response = await APIHelper.getData(url + '?search=1&limit=50')
    }
    try {
      const data = response.data
      if (data.success) {
        commit('setRegionFGDetailContractsContents', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  }
}

const getters = {
  getRegionFgSg (state) {
    return state.regionFgSg
  },
  getRegionFGDetailContracts (state) {
    return state.regionFGDetailContractsContents
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
