<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row mh-100vh">
        <!-- Start: Background image -->
        <div
          class="col-lg-5 col-md-5 col-sm-12 col-12 d-flex align-items-end login-bg-block"
        ></div>
        <!-- End: Background image -->
        <!-- Start: Login block -->
        <div class="col-12 col-sm-12 col-md-7 col-lg-7">
          <div class="auth-block">
            <div>
              <div class="text-center mb-55">
                <img :src="logo" alt="Logo" width="222"/>
              </div>
              <form @submit.prevent="resetEmail">
                <div class="form-group with-prefix mb-55">
                  <input
                    v-model="email"
                    class="form-control w-email"
                    placeholder="Email"
                    type="email"
                  />
                  <div
                    v-if="$v.$error & !$v.email.required"
                    class="error">
                    Email is required.
                  </div>
                </div>
                <div v-show="errorResponse.message" class="alert alert-danger w-alert" role="alert">
                  {{ errorResponse.message }}
                </div>
                <div class="w-100 text-center">
                  <button
                    :disabled="!isSaveButtonActive"
                    class="btn btn-primary d-inline-block"
                    type="submit"
                  >
                  <span v-show="!isSaveButtonActive" aria-hidden="true"
                        class="spinner-border spinner-border-sm"
                        role="status"></span>
                    Send password reset email
                  </button>
                  <p class="mt-20">
                    <router-link :to="{name: 'Login'}" class="pass-forg">Login</router-link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- End: Login block -->
      </div>
    </div>
  </div>
</template>

<script>
import logo from '../../assets/img/logo.svg'
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import CommonHelper from '@/utils/common'
import APIHelper from '@/service/api-helper'

export default {
  name: 'ResetPassword',
  data () {
    return {
      email: '',
      errorResponse: {},
      passwordMode: true,
      logo: logo,
      isSaveButtonActive: true
    }
  },
  mixins: [FormMixin, validationMixin],
  validations: {
    email: { required }
  },
  methods: {
    async resetEmail () {
      this.isSaveButtonActive = false
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          title: '',
          text: 'Please fix error(s) in form.',
          type: 'error',
          duration: 5000
        })
        this.isSaveButtonActive = true
      } else {
        const url = CommonHelper.makePublicApiUrl('password-reset-email')
        const payload = {
          email: this.email
        }
        try {
          const response = await APIHelper.postData(url, payload)
          const data = response.data
          if (data.success) {
            this.isSaveButtonActive = true
            this.$notify({
              title: 'Email sent.',
              text: 'Password reset email sent.',
              type: 'success',
              duration: 5000
            })
            this.$v.$reset()
          } else {
            this.isSaveButtonActive = true
            this.errorResponse.message = _.get(data, 'status_message') || ''
            if (this.errorResponse.message) {
              this.$notify({
                title: '',
                text: this.errorResponse.message,
                type: 'error',
                duration: 5000
              })
            }
          }
        } catch (e) {
          return e
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 993px) {
  .w-email {
    width: 30rem !important;
  }
  .w-alert {
    word-break: break-word;
    width: 30rem !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .w-email {
    width: 25rem !important;
  }
  .w-alert {
    word-break: break-word;
    width: 25rem !important;
  }
  .auth-block form {
    padding: 0;
  }
}

</style>
