import APIHelper from '../../service/api-helper'
import CommonHelper from '../../utils/common'
import _ from 'lodash'

const state = {
  traceabilityContractsContent: []
}

const mutations = {
  setTraceabilityContractsContent (state, payload) {
    state.traceabilityContractsContent = payload
  }
}

const actions = {
  async getUserTraceabilityContractsFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/contracts')
        response = await APIHelper.getData(url + `?limit=${payload.limit}`)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getUserTraceabilityKeyStatsChart ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'countryCode') || null) {
        url = CommonHelper.makeApiUrl('traceability-key-stats-chart')
        response = await APIHelper.getData(url + `?search=1&country_code=${payload.countryCode}`)
      } else {
        url = CommonHelper.makeApiUrl('traceability-key-stats-chart')
        response = await APIHelper.getData(url)
      }
      return response
    } catch (e) {
      return e
    }
  }
}

const getters = {
  getTraceabilityContractsContents (state) {
    return state.traceabilityContractsContent
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
