import PaginateApiCaller from '../../service/paginate-api-caller'
import { IULevel } from '@/config/master-data'
import APIHelper from '@/service/api-helper'
import CommonHelper from '@/utils/common'

const state = {
  nkgCountry: {},
  globalCountry: {},
  iuLevels: [],
  siteContents: [],
  parentContentItems: []
}

const mutations = {
  setNkgCountry (state, payload) {
    state.nkgCountry = payload
  },
  setGlobalCountry (state, payload) {
    state.globalCountry = payload
  },
  setIULevels (state, payload) {
    state.iuLevels = payload
  },
  setSiteContents (state, payload) {
    state.siteContents = payload
  },
  setParentContentItems (state, payload) {
    state.parentContentItems = payload
  }
}

const actions = {
  async loadInitialData ({ commit }) {
    // const iuLevelApiCaller = new PaginateApiCaller({
    //   path: 'v1/infrastructure-unit-level',
    //   limit: 100
    // })
    // const iuLevels = await iuLevelApiCaller.response()
    // commit('setIULevels', iuLevels)

    const url = CommonHelper.makeApiUrl('nkg-country')
    let response = await APIHelper.getData(url)
    response = response.data
    commit('setNkgCountry', response)

    const globalCountryUrl = CommonHelper.makeApiUrl('global-country')
    let globalCountryResponse = await APIHelper.getData(globalCountryUrl)
    globalCountryResponse = globalCountryResponse.data
    commit('setGlobalCountry', globalCountryResponse)

    const siteContentApiCaller = new PaginateApiCaller({
      path: 'v1/site-content',
      limit: 100
    })
    const siteContents = await siteContentApiCaller.response()
    commit('setSiteContents', siteContents)

    const parentContentItemApiCaller = new PaginateApiCaller({
      path: 'v1/content-item',
      limit: 100,
      params: { 'parent-content-type-only': true }
    })
    const parentContentItems = await parentContentItemApiCaller.response()
    commit('setParentContentItems', parentContentItems)
  }
}

const getters = {
  getCountryLevel (state) {
    return state.iuLevels.find((item) => {
      return item.name === IULevel.NKG_COUNTRY.name
    })
  },
  getRegionLevel (state) {
    return state.iuLevels.find((item) => {
      return item.name === IULevel.REGION.name
    })
  },
  getGroupLevel (state) {
    return state.iuLevels.find((item) => {
      return item.name === IULevel.GROUP.name
    })
  },
  getSubGroupLevel (state) {
    return state.iuLevels.find((item) => {
      return item.name === IULevel.SUB_GROUP.name
    })
  },
  getNkgCountry (state) {
    return state.nkgCountry
  },
  getGlobalCountry (state) {
    return state.globalCountry
  },
  getContentByAppModule: state => appModuleName => {
    return state.siteContents.find((item) => {
      return item.app_module.name === appModuleName
    }) || {}
  },
  getParentContentItemByContentType: state => (contentType, appModuleName) => {
    return state.parentContentItems.find((item) => {
      return item.content_type === contentType &&
        item.content && item.content.app_module &&
        item.content.app_module.name === appModuleName
    }) || {}
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
