<template>
  <section class="mt-50">
    <bw-loader v-if="loading"/>
    <div v-if="!loading" class="container">
      <welcome-message :subtitle="greetingsContent.subtitle" :title="greetingsContent.title"/>
      <div class="row welcome-items">
        <div
          v-for="card in cardContents" :key="card.tsync_id"
          class="col-lg-4 col-sm-12 col-12 pr-25 mb-20">
          <welcome-card
            :cover-image="card.photo ? card.photo.relative_url : ''"
            :description="card.description"
            :icon="card.icon ? card.icon.relative_url : ''"
            :more-link="card.more_link"
            :title="card.title"
            class="mb-4"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import WelcomeCard from './WelcomeCard'
import WelcomeMessage from './WelcomeMessage'
import BwLoader from '@/components/ui/BwLoader'
import { ContentTypes } from '@/config/constants'
import UtilityMixin from '../../../mixins/utility-mixin'
import _ from 'lodash'

export default {
  name: 'WelcomeDashboard',
  components: {
    BwLoader,
    WelcomeMessage,
    WelcomeCard
  },
  mixins: [UtilityMixin],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    contents () {
      return this.$store.getters.getWelcomeContents
    },
    greetingsContent () {
      const _content = this.$_.sortBy(this.contents, 'order').find((item) => {
        return item.content_type === ContentTypes.GREETING
      })
      return _content !== undefined ? _content : {
        title: '',
        subtitle: ''
      }
    },
    cardContents () {
      const multiWidgetContent = _.head(_.sortBy(this.contents.filter((item) => {
        return item.content_type === ContentTypes.MULTI_WIDGET
      }), 'order'))
      if (multiWidgetContent === undefined) {
        return []
      }
      let _contents = this.contents.filter((item) => {
        return item.content_type === ContentTypes.CARD && item.parent === multiWidgetContent.id
      })
      _contents = _.sortBy(_contents, function (item) {
        return parseInt(item.order, 10)
      })
      return _contents !== undefined ? _contents : []
    }
  },
  created () {
    this.getWelcomePageContent()
  },
  methods: {
    async getWelcomePageContent () {
      this.loading = true
      await this.$store.dispatch('getWelcomeContentsFromServer')
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
