<template>
  <div class="bw-loader">
    <div class="form-loader"></div>
    <h1 v-show="!smLoader" class="text-center">{{ message }}</h1>
    <h5 v-show="smLoader" class="text-center">{{ message }}</h5>
  </div>
</template>

<script>
export default {
  name: 'BwLoader',
  props: {
    message: {
      type: String,
      required: false,
      default: 'Loading...'
    },
    smLoader: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>
.form-loader,
.form-loader:before,
.form-loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.form-loader {
  color: #006330;
  margin: 40px auto;
  position: relative;
  font-size: 8px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.form-loader:before,
.form-loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.form-loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.form-loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
</style>
