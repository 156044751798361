import APIHelper from '../../service/api-helper'
import CommonHelper from '../../utils/common'
import Menus from '../../config/menus'
import { ContentTypes } from '@/config/constants'
import _ from 'lodash'

const state = {
  next: [],
  previous: [],
  traceabilityContents: [],
  traceabilityContractsContent: [],
  traceabilitySecondaryContractsContent: [],
  traceabilityBillOfLadingContent: [],
  traceabilityContainerContent: [],
  traceabilityImporterContractRefineContent: [],
  traceabilityContractRegionGroupsContent: [],
  traceabilityContractDetailContent: {},
  traceabilityRegionMetaContent: [],
  traceabilityGroupMetaContent: [],
  traceabilitySubGroupMetaContent: []
}

const mutations = {
  setNext (state, payload) {
    state.next = payload
  },
  setPrevious (state, payload) {
    state.previous = payload
  },
  setTraceabilityContent (state, payload) {
    state.traceabilityContents = payload
  },
  setTraceabilityContractsContent (state, payload) {
    state.traceabilityContractsContent = payload
  },
  setTraceabilitySecondaryContractsContent (state, payload) {
    state.traceabilitySecondaryContractsContent = payload
  },
  setTraceabilityBillOfLadingContent (state, payload) {
    state.traceabilityBillOfLadingContent = payload
  },
  setTraceabilityContainerContent (state, payload) {
    state.traceabilityContainerContent = payload
  },
  setTraceabilityImporterContractRefineContent (state, payload) {
    state.traceabilityImporterContractRefineContent = payload
  },
  setTraceabilityContractRegionGroupsContent (state, payload) {
    state.traceabilityContractRegionGroupsContent = payload
  },
  setTraceabilityContractDetailContent (state, payload) {
    state.traceabilityContractDetailContent = payload
  },
  setTraceabilityRegionMetaContent (state, payload) {
    state.traceabilityRegionMetaContent = payload
  },
  setTraceabilityGroupMetaContent (state, payload) {
    state.traceabilityGroupMetaContent = payload
  },
  setTraceabilitySubGroupMetaContent (state, payload) {
    state.traceabilitySubGroupMetaContent = payload
  },
  addOrUpdateTraceabilityContent (state, payload) {
    const contentItemIndex = state.traceabilityContents.findIndex(item => item.id === payload.id)
    if (contentItemIndex !== -1) {
      state.traceabilityContents.splice(contentItemIndex, 1, payload)
    } else {
      state.traceabilityContents.push(payload)
    }
  }
}

const actions = {
  async getContractsFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/contracts')
        response = await APIHelper.getData(url + `?limit=${payload.limit}`)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getBillOfLadingsFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/bill-of-lading')
        response = await APIHelper.getData(url + `?limit=${payload.limit}`)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getContainersFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/container')
        response = await APIHelper.getData(url + `?limit=${payload.limit}`)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getContractRegionGroupsFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/contract-region-groups')
        response = await APIHelper.getData(url + `?limit=${payload.limit}`)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getSecondaryContractsFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/secondary-contracts')
        response = await APIHelper.getData(url + `?limit=${payload.limit}`)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getImporterContractRefineFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/importer-contract-refine')
        response = await APIHelper.getData(url + `?limit=${payload.limit}`)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getTraceabilityContentsFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/content-item', { 'app-module': Menus.traceability.key })
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setTraceabilityContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getTraceabilityContractsFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/contracts')
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setTraceabilityContractsContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getTraceabilitySecondaryContractsFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/secondary-contracts')
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setTraceabilitySecondaryContractsContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getTraceabilityBillOfLadingFromServer ({ commit, state }, payload) {
    let url, response
    if (_.get(payload, 'contract') || null) {
      url = CommonHelper.makeApiUrl('v1/bill-of-lading')
      response = await APIHelper.getData(url + `?search=1&contract:id=${payload.contract}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/bill-of-lading')
      response = await APIHelper.getData(url)
    }
    try {
      const data = response.data
      if (data.success) {
        commit('setTraceabilityBillOfLadingContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getTraceabilityContainerFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/container')
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setTraceabilityContainerContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getTraceabilityImporterContractRefineFromServer ({ commit, state }, payload) {
    let url, response
    if (_.get(payload, 'contract') || null) {
      url = CommonHelper.makeApiUrl('v1/importer-contract-refine')
      response = await APIHelper.getData(url + `?search=1&contract:id=${payload.contract}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/importer-contract-refine')
      response = await APIHelper.getData(url + '?search=1&limit=50')
    }
    try {
      const data = response.data
      if (data.success) {
        commit('setTraceabilityImporterContractRefineContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getTraceabilityContractRegionGroupsFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/contract-region-groups')
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setTraceabilityContractRegionGroupsContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getTraceabilityContractDetailFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/contracts/' + payload.id)
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setTraceabilityContractDetailContent', data)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getTraceabilityRegionMetaFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/region-meta')
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setTraceabilityRegionMetaContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getTraceabilityGroupMetaFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/group-meta')
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setTraceabilityGroupMetaContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getTraceabilitySubGroupMetaFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/sub-group-meta')
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setTraceabilitySubGroupMetaContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateTraceabilityContents ({ commit, state }, payload) {
    let url
    if (payload.id) {
      url = CommonHelper.makeApiUrl(`v1/content-item/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/content-item')
    }
    try {
      let response
      if (payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      const data = response.data
      if (data.success) {
        commit('addOrUpdateTraceabilityContent', data)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateTraceabilityContractsContent ({ commit, state }, payload) {
    let url
    if (!_.isEmpty(payload) && payload.id) {
      url = CommonHelper.makeApiUrl(`v1/contracts/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/contracts')
    }
    try {
      let response
      if (!_.isEmpty(payload) && payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateTraceabilitySecondaryContractsContent ({ commit, state }, payload) {
    let url
    if (!_.isEmpty(payload) && payload.id) {
      url = CommonHelper.makeApiUrl(`v1/secondary-contracts/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/secondary-contracts')
    }
    try {
      let response
      if (!_.isEmpty(payload) && payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateTraceabilityBillOfLadingContent ({ commit, state }, payload) {
    let url
    if (!_.isEmpty(payload) && payload.id) {
      url = CommonHelper.makeApiUrl(`v1/bill-of-lading/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/bill-of-lading')
    }
    try {
      let response
      if (!_.isEmpty(payload) && payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateTraceabilityContainerContent ({ commit, state }, payload) {
    let url
    if (!_.isEmpty(payload) && payload.id) {
      url = CommonHelper.makeApiUrl(`v1/container/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/container')
    }
    try {
      let response
      if (!_.isEmpty(payload) && payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateTraceabilityImporterContractRefineContent ({ commit, state }, payload) {
    let url
    if (!_.isEmpty(payload) && payload.id) {
      url = CommonHelper.makeApiUrl(`v1/importer-contract-refine/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/importer-contract-refine')
    }
    try {
      let response
      if (!_.isEmpty(payload) && payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateTraceabilityContractRegionGroupsContent ({ commit, state }, payload) {
    let url
    if (!_.isEmpty(payload) && payload.id) {
      url = CommonHelper.makeApiUrl(`v1/contract-region-groups/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/contract-region-groups')
    }
    try {
      let response
      if (!_.isEmpty(payload) && payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      return response
    } catch (e) {
      return e
    }
  }
}

const getters = {
  getNext (state) {
    return state.next
  },
  getPrevious (state) {
    return state.previous
  },
  getTraceabilityContents (state) {
    return state.traceabilityContents
  },
  getTraceabilityRegionMetaContents (state) {
    return state.traceabilityRegionMetaContent
  },
  getTraceabilityGroupMetaContents (state) {
    return state.traceabilityGroupMetaContent
  },
  getTraceabilitySubGroupMetaContents (state) {
    return state.traceabilitySubGroupMetaContent
  },
  getTraceabilityAllCountryContractsContents (state) {
    return state.traceabilityContractsContent
  },
  getTraceabilityAllCountrySecondaryContractsContents (state) {
    return state.traceabilitySecondaryContractsContent
  },
  getTraceabilityAllCountryBillOfLadingContents (state) {
    return state.traceabilityBillOfLadingContent
  },
  getTraceabilityAllCountryContainerContents (state) {
    return state.traceabilityContainerContent
  },
  getTraceabilityAllCountryImporterContractRefineContents (state) {
    return state.traceabilityImporterContractRefineContent
  },
  getTraceabilityAllCountryContractRegionGroupsContents (state) {
    return state.traceabilityContractRegionGroupsContent
  },
  getTraceabilityEachCountryContractsContents: state => countryName => {
    const _content = state.traceabilityContractsContent.filter((item) => {
      return item.country_name !== null && item.country_name === countryName
    })
    return _content || []
  },
  getTraceabilityContractDetailContents (state) {
    return state.traceabilityContractDetailContent
  },
  getTraceabilityAllCountryGreetingContent (state) {
    const _content = state.traceabilityContents.find((item) => {
      return item.content_type === ContentTypes.GREETING && item.country === null
    })
    return _content || {}
  },
  getTraceabilityAllCountryChartContent (state) {
    const _content = state.traceabilityContents.find((item) => {
      return item.content_type === ContentTypes.CHART && item.country === null
    })
    return _content || {}
  },
  getTraceabilityCountryGreetingContent: state => countryName => {
    const _content = state.traceabilityContents.find((item) => {
      return item.content_type === ContentTypes.GREETING && item.country !== null && item.country.name === countryName
    })
    return _content || {}
  },
  getTraceabilityCountryChartContent: state => countryName => {
    const _content = state.traceabilityContents.find((item) => {
      return item.content_type === ContentTypes.CHART && item.country !== null && item.country.name === countryName
    })
    return _content || {}
  },
  getTraceabilityCountryKeyStatistics: state => countryName => {
    const keyStatisticsContentType = state.traceabilityContents.find(
      item => item.content_type === ContentTypes.KEY_STATISTICS)
    if (!keyStatisticsContentType) return []

    let _contents = state.traceabilityContents.filter((item) => {
      return item.parent === keyStatisticsContentType.id &&
        item.content_type === ContentTypes.MINI_CARD &&
        item.country !== null && item.country.name === countryName
    })
    _contents = _.sortBy(_contents, function (item) {
      return parseInt(item.order, 10)
    })
    return _contents || []
  },
  getTraceabilityAllCountryKeyStatistics (state) {
    const keyStatisticsContentType = state.traceabilityContents.find(
      item => item.content_type === ContentTypes.KEY_STATISTICS)
    if (!keyStatisticsContentType) return []

    let _contents = state.traceabilityContents.filter((item) => {
      return item.parent === keyStatisticsContentType.id &&
        item.content_type === ContentTypes.MINI_CARD && item.country == null
    })
    _contents = _.sortBy(_contents, function (item) {
      return parseInt(item.order, 10)
    })
    return _contents || []
  },
  getTraceabilityResourcesByContractId: state => (year) => {
    const _contents = state.traceabilityContents.filter((item) => {
      return item.content_type === ContentTypes.RESOURCE_MINI_CARD && item.year === year
    })
    return _contents || []
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
