import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import lodash from 'lodash'
import moment from 'moment'
import EventBus from './utils/event-bus'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
// Globally registered package Import here
import HighchartsVue from 'highcharts-vue'
// Include Custom Styles
// Vuelidate
import './assets/scss/style.scss'
import './assets/scss/override.scss'

import Vuelidate from 'vuelidate'
// Notification
import Notifications from 'vue-notification'
import * as Bowser from 'bowser'

Vue.use(Vuelidate)

// Globally registered package Registering here
Vue.use(HighchartsVue)
Vue.use(VueSweetalert2)

Vue.config.performance = true

Vue.use(Notifications)
// Google map
Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: 'AIzaSyCA75hhJDcCxFfVVMo7GteuRQ2ZBlssvOY'
    // libraries: 'roadmap' // necessary for places input
  }
})

Vue.config.errorHandler = function (e, vm, info) {
  const _ = lodash
  let browserMeta = {}
  const error = {}
  browserMeta = Bowser.parse(window.navigator.userAgent)
  const connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection
  browserMeta.connection = {
    connectionType: _.get(connection, 'effectiveType') || '',
    rtt: _.get(connection, 'rtt') || '',
    downlink: _.get(connection, 'downlink') || ''
  }
  error.message = _.get(e, 'message') || ''
  error.stacktrace = _.get(e, 'stack') || ''
  error.path_url = window.location.href
  error.meta_info = {
    browser: browserMeta,
    screen: {
      width: _.get(window, 'screen.width') || '',
      height: _.get(window, 'screen.height') || '',
      orientation: _.get(window, 'screen.orientation.type') || ''
    }
  }
  error.organization = vm.$store.getters.getOrganization || 1
  error.message = _.get(e, 'message') || ''
  error.url = _.get(e, 'config.url') || ''
  error.request_type = _.get(e, 'config.method') || ''
  error.token = _.get(e, 'config.headers.Authorization') || ''
  error.request_data = _.get(e, 'config.data') || ''
  vm.$store.dispatch('logError', error)
}

Vue.config.warnHandler = function (msg, vm, trace) {
  const _ = lodash
  let browserMeta = {}
  const error = {}
  browserMeta = Bowser.parse(window.navigator.userAgent)
  const connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection
  browserMeta.connection = {
    connectionType: _.get(connection, 'effectiveType') || '',
    rtt: _.get(connection, 'rtt') || '',
    downlink: _.get(connection, 'downlink') || ''
  }
  error.message = _.get(msg, 'message') || ''
  error.stacktrace = _.get(msg, 'stack') || trace
  error.path_url = window.location.href
  error.meta_info = {
    browser: browserMeta,
    screen: {
      width: _.get(window, 'screen.width') || '',
      height: _.get(window, 'screen.height') || '',
      orientation: _.get(window, 'screen.orientation.type') || ''
    }
  }
  error.organization = vm.$store.getters.getOrganization || 1
  error.message = _.get(msg, 'message') || ''
  error.url = _.get(msg, 'config.url') || ''
  error.request_type = _.get(msg, 'config.method') || ''
  error.token = _.get(msg, 'config.headers.Authorization') || ''
  error.request_data = _.get(msg, 'config.data') || ''
  vm.$store.dispatch('logError', error)
}

Vue.config.productionTip = true

Vue.prototype.$_ = lodash
Vue.prototype.$moment = moment
Vue.prototype.$ebus = EventBus

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
