import APIHelper from '../../service/api-helper'
import CommonHelper from '../../utils/common'
import _ from 'lodash'

const state = {
  contentItems: []
}

const mutations = {
  setContentItemsContent (state, payload) {
    state.contentItems = payload
  }
}

const actions = {
  async getContentItemsFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/content-item')
        response = await APIHelper.getData(url + '?search=1')
      }
      return response
    } catch (e) {
      return e
    }
  },
  async putBase64Photo ({ commit, state }, payload) {
    const _url = CommonHelper.makeApiUrl(`image-file/${payload.id}`)
    try {
      return await APIHelper.putData(_url, payload)
    } catch (e) {
      return e
    }
  },
  async putPhotoFile ({ commit, state }, payload) {
    const _url = CommonHelper.makeApiUrl(`multi-part-file/${payload.id}`)
    const _headers = {
      'Content-Type': 'multipart/form-data'
    }
    try {
      return await APIHelper.putData(_url, payload.formData, _headers)
    } catch (e) {
      return e
    }
  },
  async putFile ({ commit, state }, payload) {
    const _url = CommonHelper.makeApiUrl(`file-object/${payload.id}`)
    const _headers = {
      'Content-Type': 'multipart/form-data'
    }
    try {
      return await APIHelper.putData(_url, payload.formData, _headers)
    } catch (e) {
      return e
    }
  },
  async deleteContentItem ({ commit, state }, id) {
    const _url = CommonHelper.makeApiUrl(`content-item/${id}`)
    try {
      return await APIHelper.deleteData(_url)
    } catch (e) {
      return e
    }
  },
  async logError ({ commit, state }, payload) {
    const _url = CommonHelper.makeApiUrl('v1/frontend-error-logs')
    try {
      return await APIHelper.postData(_url, payload)
    } catch (e) {
      return e
    }
  }
}

const getters = {
  getContentItemsContents (state) {
    return state.contentItems
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
