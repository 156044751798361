import APIHelper from '../../service/api-helper'
import CommonHelper from '../../utils/common'
import { ContentTypes } from '@/config/constants'
import Menus from '@/config/menus'
import _ from 'lodash'
import PaginateApiCaller from '@/service/paginate-api-caller'

const state = {
  regionFarmerGroupDetailContent: [],
  regionFarmerGroupContentItem: [],
  regionFarmerGroupContractRegionGroupsContent: [],
  regionFarmerGroupLocationContent: [],
  regionFarmerGroup: [],
  regionFarmerSubGroup: [],
  regionFarmerRegion: []
}

const mutations = {
  setRegionFarmerGroupsDetailContent (state, payload) {
    state.regionFarmerGroupDetailContent = payload
  },
  setRegionFarmerGroupMenuContentItem (state, payload) {
    state.regionFarmerGroupContentItem = payload
  },
  setRegionFarmerGroupContent (state, payload) {
    state.regionFarmerGroup = payload
  },
  setRegionFarmerSubGroupContent (state, payload) {
    state.regionFarmerSubGroup = payload
  },
  setRegionFarmerRegionContent (state, payload) {
    state.regionFarmerRegion = payload
  },
  addOrUpdateRegionFarmerGroupContent (state, payload) {
    const contentItemIndex = state.regionFarmerGroupContentItem.findIndex(item => item.id === payload.id)
    if (contentItemIndex !== -1) {
      state.regionFarmerGroupContentItem.splice(contentItemIndex, 1, payload)
    } else {
      state.regionFarmerGroupContentItem.push(payload)
    }
  },
  setRegionFarmerGroupContractRegionGroupsContent (state, payload) {
    state.regionFarmerGroupContractRegionGroupsContent = payload
  },
  setRegionFarmerGroupLocationContent (state, payload) {
    state.regionFarmerGroupLocationContent = payload
  }
}

const actions = {
  async getRegionsFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/region-meta')
        response = await APIHelper.getData(url + `?limit=${payload.limit}`)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getGroupsFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/group-meta')
        response = await APIHelper.getData(url + `?limit=${payload.limit}`)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getSubGroupsFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/sub-group-meta')
        response = await APIHelper.getData(url + `?limit=${payload.limit}`)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getRegionFarmerGroupsContractDetailFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/' + payload.iu_type + '-meta/' + payload.id)
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setRegionFarmerGroupsDetailContent', data)
      }
      return response
    } catch (e) {
      return e
    }
  },
  //  This fetch Key statistics data
  async getRegionFarmerGroupMenuContentItemsFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/content-item', { 'app-module': Menus.regionFG.key })
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setRegionFarmerGroupMenuContentItem', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getRegionFarmerRegionContentsFromServer ({ commit, state }, payload) {
    let url
    if (!_.isEmpty(payload) && payload.id) {
      url = CommonHelper.makeApiUrl(`v1/region-meta/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/region-meta')
    }
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        if (!_.isEmpty(payload) && payload.id) {
          commit('setRegionFarmerRegionContent', data)
        } else {
          commit('setRegionFarmerRegionContent', data.results)
        }
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getRegionFarmerGroupContentsFromServer ({ commit, state }, payload) {
    let url
    if (!_.isEmpty(payload) && payload.id) {
      url = CommonHelper.makeApiUrl(`v1/group-meta/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/group-meta')
    }
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        if (!_.isEmpty(payload) && payload.id) {
          commit('setRegionFarmerGroupContent', data)
        } else {
          commit('setRegionFarmerGroupContent', data.results)
        }
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getRegionFarmerSubGroupContentsFromServer ({ commit, state }, payload) {
    let url, response
    if (!_.isEmpty(payload) && payload.id) {
      url = CommonHelper.makeApiUrl(`v1/sub-group-meta/${payload.id}`)
      response = await APIHelper.getData(url)
    } else {
      url = CommonHelper.makeApiUrl('v1/sub-group-meta')
      response = await APIHelper.getData(url + `?limit=${_.get(payload, 'limit') || 5}`)
    }
    try {
      const data = response.data
      if (data.success) {
        if (!_.isEmpty(payload) && payload.id) {
          commit('setRegionFarmerSubGroupContent', data)
        } else {
          commit('setRegionFarmerSubGroupContent', data.results)
        }
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getSubGroupListFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/sub-group-meta')
        response = await APIHelper.getData(url + `?limit=${payload.limit}&table_type=${payload.table_type}`)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateRegionFarmerSubGroupContent ({ commit, state }, payload) {
    let url
    if (!_.isEmpty(payload) && payload.id) {
      url = CommonHelper.makeApiUrl(`v1/sub-group-meta/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/sub-group-meta')
    }
    try {
      let response
      if (!_.isEmpty(payload) && payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateRegionFarmerRegionContent ({ commit, state }, payload) {
    let url
    if (!_.isEmpty(payload) && payload.id) {
      url = CommonHelper.makeApiUrl(`v1/region-meta/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/region-meta')
    }
    try {
      let response
      if (!_.isEmpty(payload) && payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateRegionFarmerGroupContent ({ commit, state }, payload) {
    let url
    if (!_.isEmpty(payload) && payload.id) {
      url = CommonHelper.makeApiUrl(`v1/group-meta/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/group-meta')
    }
    try {
      let response
      if (!_.isEmpty(payload) && payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateRegionFarmerGroupContentItem ({ commit, state }, payload) {
    let url
    if (payload.id) {
      url = CommonHelper.makeApiUrl(`v1/content-item/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/content-item')
    }
    try {
      let response
      if (payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      const data = response.data
      if (data.success) {
        commit('addOrUpdateRegionFarmerGroupContent', data)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getRegionFarmerGroupContractRegionGroupsFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/contract-region-groups')
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setRegionFarmerGroupContractRegionGroupsContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getRegionFarmerGroupLocationFromServer ({ commit, state }, payload) {
    let contractRegionGroupApiCaller
    if (_.get(payload, 'countryCode')) {
      contractRegionGroupApiCaller = new PaginateApiCaller({
        path: 'v2/contract-region-groups',
        limit: 50,
        params: { 'region:parent:public_code': payload.countryCode }
      })
    } else {
      contractRegionGroupApiCaller = new PaginateApiCaller({
        path: 'v2/contract-region-groups',
        limit: 50
      })
    }

    const contractRegionGroups = await contractRegionGroupApiCaller.response()
    commit('setRegionFarmerGroupLocationContent', contractRegionGroups)
  },
  async getSpecificRegionFarmerGroupLocationFromServer ({ commit, state }, payload) {
    let url, response
    if (_.get(payload, 'region') || null) {
      url = CommonHelper.makeApiUrl('v3/region-meta')
      response = await APIHelper.getData(url + `?search=1&limit=100&id=${payload.region}`)
    } else if (_.get(payload, 'group') || null) {
      url = CommonHelper.makeApiUrl('v3/group-meta')
      response = await APIHelper.getData(url + `?search=1&limit=100&id=${payload.group}`)
    } else if (_.get(payload, 'sub_group') || null) {
      url = CommonHelper.makeApiUrl('v3/sub-group-meta')
      response = await APIHelper.getData(url + `?search=1&limit=100&id=${payload.sub_group}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/contract-region-groups')
      response = await APIHelper.getData(url + '?search=1&limit=50')
    }
    try {
      const data = response.data
      if (data.success) {
        commit('setRegionFarmerGroupLocationContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  }
}

const getters = {
  getRegionFarmerGroupAllCountryKeyStatistics (state) {
    const parentContentType = state.regionFarmerGroupContentItem.find(
      item => item.content_type === ContentTypes.KEY_STATISTICS)
    if (!parentContentType) return []

    let _contents = state.regionFarmerGroupContentItem.filter((item) => {
      return item.parent === parentContentType.id &&
        item.content_type === ContentTypes.MINI_CARD && item.country == null
    })
    _contents = _.sortBy(_contents, function (item) {
      return parseInt(item.order, 10)
    })
    return _contents || []
  },
  getRegionFarmerGroupEachCountryKeyStatistics: state => countryName => {
    const parentContentType = state.regionFarmerGroupContentItem.find(
      item => item.content_type === ContentTypes.KEY_STATISTICS)
    if (!parentContentType) return []

    let _contents = state.regionFarmerGroupContentItem.filter((item) => {
      return item.parent === parentContentType.id &&
        item.content_type === ContentTypes.MINI_CARD &&
        item.country !== null && item.country.name === countryName
    })
    _contents = _.sortBy(_contents, function (item) {
      return parseInt(item.order, 10)
    })
    return _contents || []
  },
  getRegionFarmerGroupAllCountrySubGroupContents (state) {
    return state.regionFarmerSubGroup
  },
  getRegionFarmerGroupEachCountrySubGroupContents: state => countryName => {
    const _content = state.regionFarmerSubGroup.filter((item) => {
      return item.country_name !== null && item.country_name === countryName
    })
    return _content || []
  },
  getRegionFarmerGroupAllCountryGroupContents (state) {
    return state.regionFarmerGroup
  },
  getRegionFarmerGroupAllCountryRegionContents (state) {
    return state.regionFarmerRegion
  },
  getRegionFarmerGroupDetailContents (state) {
    return state.regionFarmerGroupDetailContent
  },
  getRegionFarmerGroupAllCountryContractRegionGroupsContents (state) {
    return state.regionFarmerGroupContractRegionGroupsContent
  },
  getRegionFarmerGroupLocationContents (state) {
    return state.regionFarmerGroupLocationContent
  },
  getRegionFarmerGroupResourcesBySubGroupId: state => (year) => {
    const _contents = state.regionFarmerGroupContentItem.filter((item) => {
      return item.content_type === ContentTypes.RESOURCE_MINI_CARD && item.year === year
    })
    return _contents || []
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
