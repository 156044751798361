export default {
  welcome: {
    key: 'welcome',
    name: 'Welcome',
    order: 10
  },
  enablingFarmers: {
    key: 'enabling-farmers',
    name: 'Enabling Farmers',
    order: 20
  },
  inclusiveSustainability: {
    key: 'inclusive-sustainability',
    name: 'Inclusive Sustainability',
    order: 30
  },
  traceability: {
    key: 'traceability',
    name: 'Traceability',
    order: 40
  },
  regionFG: {
    key: 'region-and-farmer-groups',
    name: 'Region & Farmer Groups/Labels',
    order: 50
  },
  resources: {
    key: 'resources',
    name: 'Resources',
    order: 60
  }
}
