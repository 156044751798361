export default {
  auth ({ next, store }) {
    if (!store.getters.isAuthenticated) {
      return next('/login')
    }
    return next()
  },
  isAdmin ({ next, store }) {
    if (!store.getters.isAuthenticated) {
      return next('/login')
    } else if (!store.getters.iAmAdmin) {
      return next('/no-access')
    }
    return next()
  },
  notAuth ({ next, store }) {
    if (store.getters.isAuthenticated) {
      if (store.getters.iAmAdmin) {
        return next('admin/welcome')
      } else {
        return next('/welcome')
      }
    }
    return next()
  },
  public ({ next, store }) {
    return next()
  }
}
