<template>
  <div>
    <div v-if="message" class="global-top-message">
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalTopMessage',
  data () {
    return {
      message: ''
    }
  },
  mounted () {
    this.$ebus.$on('GLOBAL_ERROR', (mgs) => {
      this.message = mgs
    })
  }
}
</script>

<style lang="scss" scoped>
.global-top-message {
  p {
    padding: 10px;
    margin: 0;
    background-color: orangered;
    color: #ffffff;
    text-align: center;
  }
}
</style>
