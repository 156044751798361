<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row mh-100vh">
        <!-- Start: Background image -->
        <div
          class="col-lg-5 col-md-5 col-sm-12 col-12 d-flex align-items-end login-bg-block"
        ></div>
        <!-- End: Background image -->
        <!-- Start: Login block -->
        <div class="col-12 col-sm-12 col-md-7 col-lg-7">
          <div class="auth-block">
            <div>
              <div class="text-center mb-55">
                <img :src="logo" alt="Logo" width="222"/>
              </div>
              <form @submit.prevent="resetPassword">
                <div class="form-group with-prefix mb-55">
                  <input
                    v-model="newPassword" :disabled="!validResetLink" class="form-control"
                    placeholder="New password" type="password">
                  <div
                    v-if="$v.$error & !$v.newPassword.required"
                    class="error">
                    New password is required.
                  </div>
                  <div
                    v-if="$v.$error & !$v.newPassword.minLength"
                    class="error">
                    New password must have at least {{ $v.newPassword.$params.minLength.min }} characters.
                  </div>
                </div>
                <div class="form-group with-prefix mb-55">
                  <input
                    v-model="confirmPassword" :disabled="!validResetLink" class="form-control"
                    placeholder="Confirm password" type="password">
                  <div
                    v-if="$v.$error & !$v.confirmPassword.required"
                    class="error">
                    Confirm password is required.
                  </div>
                  <div
                    v-if="$v.$error & !$v.confirmPassword.minLength"
                    class="error">
                    Confirm password must have at least {{ $v.confirmPassword.$params.minLength.min }} characters.
                  </div>
                  <div
                    v-if="$v.$error & !$v.confirmPassword.sameAsPassword"
                    class="error">
                    Confirm password must be same as new password.
                  </div>
                </div>
                <div v-show="errorResponse.message" class="alert alert-danger" role="alert">
                  {{ errorResponse.message }}
                </div>
                <div class="w-100 text-center">
                  <button
                    :disabled="!isSaveButtonActive  || !validResetLink"
                    class="btn btn-primary d-inline-block"
                    type="submit"
                  >
                  <span v-show="!isSaveButtonActive" aria-hidden="true"
                        class="spinner-border spinner-border-sm"
                        role="status"></span>
                    Reset Password
                  </button>
                  <p class="mt-20">
                    <router-link :to="{name: 'Login'}" class="pass-forg">Login</router-link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- End: Login block -->
      </div>
    </div>
  </div>
</template>

<script>
import logo from '../../assets/img/logo.svg'
import { minLength, required, sameAs } from 'vuelidate/lib/validators'
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import CommonHelper from '@/utils/common'
import APIHelper from '@/service/api-helper'

export default {
  name: 'ResetPasswordConfirm',
  data () {
    return {
      errorResponse: {},
      passwordMode: true,
      logo: logo,
      isSaveButtonActive: true,
      validResetLink: true,
      newPassword: '',
      confirmPassword: ''
    }
  },
  mixins: [FormMixin, validationMixin],
  validations: {
    newPassword: { required, minLength: minLength(4) },
    confirmPassword: { required, minLength: minLength(4), sameAsPassword: sameAs('newPassword') }
  },
  methods: {
    resetPassword: async function () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          title: '',
          text: 'Please fix error(s) in form.',
          type: 'error',
          duration: 5000
        })
      } else {
        const url = CommonHelper.makePublicApiUrl('password-reset-confirm')
        const payload = {
          uid: this.$route.params.uid,
          token: this.$route.params.token,
          password: this.newPassword,
          password2: this.confirmPassword
        }
        try {
          const response = await APIHelper.postData(url, payload)
          const data = response.data
          if (data.success) {
            this.$notify({
              title: 'Password changed',
              text: 'Password changed successfully.',
              type: 'success',
              duration: 5000
            })
            this.oldPassword = ''
            this.newPassword = ''
            this.confirmPassword = ''
            this.$v.$reset()
          } else {
            this.$notify({
              title: '',
              text: 'Password can\'t successfully.',
              type: 'error',
              duration: 5000
            })
            const oldPasswordWrong = _.get(data, 'old_password.old_password') || ''
            if (oldPasswordWrong) {
              this.$notify({
                title: 'Wrong password',
                text: oldPasswordWrong,
                type: 'error',
                duration: 5000
              })
            }
            const passwordNotMatch = _.get(data, 'password.password') || ''
            if (passwordNotMatch) {
              this.$notify({
                title: '',
                text: passwordNotMatch,
                type: 'error',
                duration: 5000
              })
            }
          }
        } catch (e) {
          return e
        }
      }
    },
    checkPasswordResetLink: async function () {
      const url = CommonHelper.makePublicApiUrl('password-reset-validity')
      const payload = {
        uid: this.$route.params.uid,
        token: this.$route.params.token
      }
      try {
        const response = await APIHelper.postData(url, payload)
        const data = response.data
        if (data.validlink) {
        } else {
          this.errorResponse.message = _.get(data, 'status_message') || ''
          this.validResetLink = false
          this.$notify({
            title: 'Invalid link',
            text: 'Password reset link is not valid or expired.',
            type: 'error',
            duration: 5000
          })
        }
      } catch (e) {
        return e
      }
    }
  },
  created () {
    this.checkPasswordResetLink()
  }
}
</script>

<style scoped>

</style>
