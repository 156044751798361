import serverConfig from '../config/server'
import store from '../store'

export default {
  makeApiUrl (path, params = null) {
    const absoluteUrl = serverConfig.serverUrl + '/api/' + path + '/'
    let paramString = ''
    if (params) {
      paramString = '?'
      Object.keys(params).map((_key) => {
        paramString += `${_key}=${params[_key]}&`
      })
    }
    return absoluteUrl + paramString
  },
  makePublicApiUrl (path, params = null) {
    const absoluteUrl = serverConfig.serverUrl + '/api/' + path + '/'
    let paramString = ''
    if (params) {
      paramString = '?'
      Object.keys(params).map((_key) => {
        paramString += `${_key}=${params[_key]}&`
      })
    }
    return absoluteUrl + paramString
  },
  makePaginatedApiUrl (path, params = null) {
    const absoluteUrl = serverConfig.serverUrl + path
    let paramString = ''
    if (params) {
      paramString = '?'
      Object.keys(params).map((_key) => {
        paramString += `${_key}=${params[_key]}&`
      })
    }
    return absoluteUrl + paramString
  },
  getApiHeaders () {
    const _headers = {
      'Content-Type': 'application/json'
    }
    if (store.getters.getToken && store.getters.isAuthenticated) {
      _headers.Authorization = 'Token ' + store.getters.getToken
    }
    return _headers
  }
}
