import VueCookie from 'vue-cookie'
import APIHelper from '../../service/api-helper'
import CommonHelper from '../../utils/common'
import { AdminRoles, ObserverRoles } from '../../config/constants'

const state = {
  auth: JSON.parse(VueCookie.get('login')),
  me: JSON.parse(VueCookie.get('me')),
  token: JSON.parse(VueCookie.get('token')),
  permissions: JSON.parse(VueCookie.get('permissions')),
  organization: JSON.parse(VueCookie.get('organization'))
}

const mutations = {
  updateLoginStatus (state, payload) {
    VueCookie.set('login', JSON.stringify(true), 1)
    VueCookie.set('me', JSON.stringify(payload.auth_info), 1)
    VueCookie.set('token', JSON.stringify(payload.token), 1)
    VueCookie.set('permissions', JSON.stringify(payload.app_top_module_assignment), 1)
    VueCookie.set('organization', JSON.stringify(payload.organization_info.id), 1)
    state.auth = true
    state.token = payload.token
    state.permissions = payload.app_top_module_assignment
    state.me = payload.auth_info
    state.organization = _.get(payload, 'organization_info.id') || null
  },
  logout (state) {
    state.auth = false
    VueCookie.delete('login')
    VueCookie.delete('token')
  }
}

const actions = {
  handleLogin ({ commit, state }, payload) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const _url = CommonHelper.makeApiUrl('login')
      const response = await APIHelper.postData(_url, payload)
      const _data = response.data
      if (_data.success) {
        commit('updateLoginStatus', _data)
        resolve(_data)
      } else {
        reject(_data)
      }
    })
  },
  handleLogout ({ commit, state }) {
    commit('logout')
  }
}

const getters = {
  isAuthenticated (state) {
    return state.auth && state.token
  },
  getToken (state) {
    return state.token
  },
  getUserInfo (state) {
    return state.me
  },
  getPermissions (state) {
    return state.permissions
  },
  getOrganization (state) {
    return state.organization
  },
  iAmAdmin (state) {
    return state.me ? AdminRoles.includes(state.me.role_name) : false
  },
  iAmObserver (state) {
    return state.me ? ObserverRoles.includes(state.me.role_name) : false
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
