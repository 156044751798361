import Utils from '../utils/common'
import axios from 'axios'
import EventBus from '../utils/event-bus'

const serverErrorCodes = [
  500, 501, 502, 503, 504, 505
]
const clientErrorCodes = [
  400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410,
  // Field Buzz Custom Codes for error
  470, 471, 472, 473, 474, 475, 476, 477, 478, 479, 480, 481
]
const successfulResponseCodes = [
  200, 201, 202, 203, 204, 205
]

export default {
  hasErrorResponse (response) {
    if (serverErrorCodes.includes(response.status)) {
      return true
    } else if (clientErrorCodes.includes(response.status)) {
      return true
    }
    return false
  },
  checkResponseStatus (response) {
    if (response === undefined || response === null) {
      EventBus.$emit('GLOBAL_ERROR', 'An error has occurred. Can\'t communicate with server. Please try again.')
      return
    }
    if (serverErrorCodes.includes(response.status)) {
      // Handle server error response here.
    } else if (clientErrorCodes.includes(response.status)) {
      // Handle server error response here.
      this.checkInvalidTokenStatus(response)
      return response
    } else if (successfulResponseCodes.includes(response.status)) {
      // Handle server error response here.
      return response
    } else {
      // Handle something else from here.
    }
  },
  checkInvalidTokenStatus (response) {
    const data = response.data
    if (data.status_code === 473) {
      EventBus.$emit('TRIGGER_LOGOUT')
    }
  },

  async getData (url, headers = {}) {
    const options = {
      headers: {
        ...Utils.getApiHeaders(),
        ...headers
      }
    }
    try {
      const response = await axios.get(url, options)
      return this.checkResponseStatus(response)
    } catch (error) {
      return this.checkResponseStatus(error.response)
    }
  },
  async postData (url, payload, headers = {}) {
    const options = {
      headers: {
        ...Utils.getApiHeaders(),
        ...headers
      }
    }
    try {
      const response = await axios.post(url, payload, options)
      return this.checkResponseStatus(response)
    } catch (error) {
      return this.checkResponseStatus(error.response)
    }
  },
  async putData (url, payload, headers = {}) {
    const options = {
      headers: {
        ...Utils.getApiHeaders(),
        ...headers
      }
    }
    try {
      const response = await axios.put(url, payload, options)
      return this.checkResponseStatus(response)
    } catch (error) {
      return this.checkResponseStatus(error.response)
    }
  },
  async patchData (url, payload, headers = {}) {
    const options = {
      headers: {
        ...Utils.getApiHeaders(),
        ...headers
      }
    }
    try {
      const response = await axios.patch(url, payload, options)
      return this.checkResponseStatus(response)
    } catch (error) {
      return this.checkResponseStatus(error.response)
    }
  },
  async deleteData (url, headers = {}) {
    const options = {
      headers: {
        ...Utils.getApiHeaders(),
        ...headers
      }
    }
    try {
      const response = await axios.delete(url, options)
      return this.checkResponseStatus(response)
    } catch (error) {
      return this.checkResponseStatus(error.response)
    }
  }
}
