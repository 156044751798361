import APIHelper from '../../service/api-helper'
import CommonHelper from '../../utils/common'
import Menus from '../../config/menus'
import { ContentTypes } from '@/config/constants'
import _ from 'lodash'

const state = {
  resourceContents: []
}

const mutations = {
  setResourceContent (state, payload) {
    state.resourceContents = payload
  },
  addOrUpdateResourceContent (state, payload) {
    const contentItemIndex = state.resourceContents.findIndex(item => item.id === payload.id)
    if (contentItemIndex !== -1) {
      state.resourceContents.splice(contentItemIndex, 1, payload)
    } else {
      state.resourceContents.push(payload)
    }
  }
}

const actions = {
  async getResourceContentsFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/content-item', { 'app-module': Menus.resources.key })
    try {
      const response = await APIHelper.getData(url)
      const data = response.data
      if (data.success) {
        commit('setResourceContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async createOrUpdateResourceContentItem ({ commit, state }, payload) {
    let url
    if (payload.id) {
      url = CommonHelper.makeApiUrl(`v1/content-item/${payload.id}`)
    } else {
      url = CommonHelper.makeApiUrl('v1/content-item')
    }
    try {
      let response
      if (payload.id) {
        response = await APIHelper.putData(url, payload)
      } else {
        response = await APIHelper.postData(url, payload)
      }
      const data = response.data
      if (data.success) {
        commit('addOrUpdateResourceContent', data)
      }
      return response
    } catch (e) {
      return e
    }
  }
}

const getters = {
  getResourceAllCountryResources (state) {
    const parentContentType = state.resourceContents.find(
      item => item.content_type === ContentTypes.RESOURCE)
    if (!parentContentType) return []

    let _contents = state.resourceContents.filter((item) => {
      return item.parent === parentContentType.id &&
        item.content_type === ContentTypes.TABLE
    })
    _contents = _.sortBy(_contents, function (item) {
      return parseInt(item.order, 10)
    })
    return _contents || []
  },
  getResourceCountryResources: state => countryName => {
    let _contents = state.resourceContents.filter((item) => {
      return item.content_type === ContentTypes.RESOURCE_MINI_CARD &&
        item.country !== null && item.country.name === countryName
    })
    _contents = _.sortBy(_contents, function (item) {
      return parseInt(item.order, 10)
    })
    return _contents || []
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
