<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row mh-100vh">
        <!-- Start: Background image -->
        <div
          class="col-lg-5 col-md-5 col-sm-12 col-12 d-flex align-items-end login-bg-block"
        ></div>
        <!-- End: Background image -->
        <!-- Start: Login block -->
        <div class="col-12 col-sm-12 col-md-7 col-lg-7">
          <div class="auth-block">
            <div>
              <div class="text-center mb-55">
                <img :src="logo" alt="Logo" width="222"/>
              </div>
              <form @submit.prevent="login">
                <div class="form-group with-prefix mb-55">
                  <div class="prefix">
                    <img
                      alt="user"
                      src="@/assets/img/icons/user-sm.png"
                    />
                  </div>
                  <input
                    v-model="formData.username"
                    class="form-control"
                    placeholder="Username"
                    type="text"
                  />
                </div>
                <div class="form-group with-prefix">
                  <div class="prefix">
                    <img
                      alt="lock"
                      src="@/assets/img/icons/lock-sm.png"
                    />
                  </div>
                  <input
                    v-model="formData.password"
                    :type="passwordMode ? 'password' : 'text'"
                    class="form-control"
                    placeholder="Password"
                  />
                  <div class="view-pass">
                    <img
                      alt="eye"
                      src="@/assets/img/icons/eye.png"
                      @click="() => passwordMode = !passwordMode"
                    />
                  </div>
                </div>
                <div class="form-group form-check mb-20">
                  <input
                    id="exampleCheck1"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label
                    class="form-check-label"
                    for="exampleCheck1"
                  >Remember me</label
                  >
                </div>
                <div v-show="errorResponse.message" class="alert alert-danger" role="alert">
                  {{ errorResponse.message }}
                </div>
                <div class="w-100 text-center">
                  <button
                    :disabled="!isSaveButtonActive"
                    class="btn btn-primary d-inline-block"
                    type="submit"
                  >
                  <span v-show="!isSaveButtonActive" aria-hidden="true"
                        class="spinner-border spinner-border-sm"
                        role="status"></span>
                    Login
                  </button>
                  <p class="mt-20">
                    <router-link
                      :to="{name: 'ResetPassword'}"
                      class="pass-forg"
                    >
                      Forgot Your Password?
                    </router-link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- End: Login block -->
      </div>
    </div>
  </div>
</template>

<script>
import logo from '../../assets/img/logo.svg'

export default {
  name: 'Login',
  data () {
    return {
      formData: {},
      errorResponse: {},
      passwordMode: true,
      logo: logo,
      isSaveButtonActive: true
    }
  },
  methods: {
    async login () {
      this.isSaveButtonActive = false
      const vm = this
      try {
        await this.$store.dispatch('handleLogin', this.formData)
        this.$store.dispatch('loadInitialData')

        if (vm.$store.getters.iAmAdmin) {
          this.isSaveButtonActive = true
          this.$router.push('/admin/welcome')
        } else {
          this.isSaveButtonActive = true
          this.$router.push('/welcome')
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.errorResponse = e
      }
    }
  }
}
</script>

<style scoped>

</style>
