<template>
  <div v-if="!loading">
    <global-top-message/>
    <navbar v-if="$store.getters.isAuthenticated"/>
    <notifications classes="fb-vue-notification" position="top right"/>
    <router-view/>
  </div>
</template>
<script>
import Navbar from './components/common/Navbar'
import UtilityMixin from './mixins/utility-mixin'
import GlobalTopMessage from '@/components/ui/GlobalTopMessage'

export default {
  components: {
    GlobalTopMessage,
    Navbar
  },
  data () {
    return {
      loading: false
    }
  },
  mixins: [UtilityMixin],
  created () {
    if (this.isAuthenticated) {
      this.loading = true
      this.$store.dispatch('loadInitialData')
      this.loading = false
    }

    this.$ebus.$on('TRIGGER_LOGOUT', () => {
      const vm = this
      this.$swal.fire({
        title: 'Session expired!!',
        text: 'You need to login again.',
        icon: 'warning',
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonColor: '#006330',
        confirmButtonText: 'Okay!'
      }).then(async (result) => {
        if (result.value) {
          vm.$store.dispatch('handleLogout')
          vm.$router.push('/login')
        }
      })
    })
  }
}
</script>

<style lang="scss">
.fb-vue-notification {
  padding: 10px;
  margin: 10px 5px;

  font-size: 1rem;

  color: #ffffff;
  background: #44A4FC;
  border-left: 5px solid #187FE7;

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #f44336;
    border-left-color: #B82E24;
    color: #fff;
    font-size: 1.2rem;
  }

  &.success {
    background: #75c88c;
    border-left-color: #42A85F;
  }
}
</style>
