<template>
  <div>
    <router-link v-if="!isExternalLink(moreLink)" :to="moreLink">&nbsp;
      <div class="card card-image-box">
        <img :alt="title" :src="coverImage | makeAbsUrl" class="card-img-top"/>
        <div class="card-body">
          <div class="d-flex">
            <div class="card-icon">
              <img :src="icon | makeAbsUrl" alt="Icon" class="image-icon"/>
            </div>
            <div class="card-content">
              <h6 class="font-18 text-f3">
                {{ title }}
              </h6>
              <p class="font-18 text-pre-wrap">{{ description | smartTextTruncate(100, '...') }}</p>
            </div>
          </div>
          <router-link v-if="!isExternalLink(moreLink)" :to="moreLink" class="btn-more" type="button">&nbsp;
            more&nbsp;<img alt="Icon" src="@/assets/img/icons/arrow-circle.svg">&nbsp;
          </router-link>
        </div>
      </div>
    </router-link>
    <a v-if="isExternalLink(moreLink)" :href="moreLink">&nbsp;
      <div class="card card-image-box">
        <img :alt="title" :src="coverImage | makeAbsUrl" class="card-img-top"/>
        <div class="card-body">
          <div class="d-flex">
            <div class="card-icon">
              <img :src="icon | makeAbsUrl" alt="Icon" class="image-icon"/>
            </div>
            <div class="card-content">
              <h6 class="font-18 text-f3">
                {{ title }}
              </h6>
              <p class="font-18 text-pre-wrap">{{ description | smartTextTruncate(100, '...') }}</p>
            </div>
          </div>
          <a v-if="isExternalLink(moreLink)" :href="moreLink" class="btn-more" type="button">&nbsp;
            more&nbsp;<img alt="Icon" src="@/assets/img/icons/arrow-circle.svg">&nbsp;
          </a>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import UtilityMixin from '../../../mixins/utility-mixin'

export default {
  name: 'WelcomeCard',
  mixins: [UtilityMixin],
  props: {
    coverImage: {
      required: false,
      type: String
    },
    icon: {
      required: true,
      type: String
    },
    title: {
      required: true,
      type: String
    },
    description: {
      required: true,
      type: String
    },
    moreLink: {
      required: false,
      type: String,
      default: '/welcome'
    }
  },
  methods: {
    isExternalLink (value) {
      return value ? !!value.includes('http') : '/'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/functions";

.card-image-box {
  height: size(450);

  .card-img-top {
    object-fit: cover;
    height: size(220);
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .card-body {
    .card-icon {
      width: size(34);
    }

    .card-content {
      width: size(400);
    }
  }
}

a:hover {
  text-decoration: none;
}
</style>
