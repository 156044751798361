import APIHelper from '../../service/api-helper'
import CommonHelper from '../../utils/common'
import { ContentTypes } from '@/config/constants'
import _ from 'lodash'

const state = {
  userResourceContents: [],
  farmerStoriesContents: [],
  allResourceContents: []
}

const mutations = {
  setUserResourceContent (state, payload) {
    state.userResourceContents = payload
  },
  setFarmerStoriesContent (state, payload) {
    state.farmerStoriesContents = payload
  },
  setAllResourceContent (state, payload) {
    state.allResourceContents = payload
  }
}

const actions = {
  async getAllResourceContentsFromServer ({ commit, state }, payload) {
    const url = CommonHelper.makeApiUrl(
      'v1/content-item', {
        content_type: ContentTypes.RESOURCE_MINI_CARD
      })
    try {
      const response = await APIHelper.getData(url + `?search=1&limit=${payload.limit}`)
      const data = response.data
      if (data.success) {
        commit('setAllResourceContent', data.results)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getCountryWiseFarmerStoriesContentsFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/content-item', {
          content_type: ContentTypes.FARMER_STORIES
        })
        response = await APIHelper.getData(url + `?search=1&limit=${payload.limit}`)
      }
      return response
    } catch (e) {
      return e
    }
  },
  async getUserResourceContentsFromServer ({ commit, state }, payload) {
    let url, response
    try {
      if (_.get(payload, 'apiUrl') || null) {
        url = CommonHelper.makePaginatedApiUrl(payload.apiUrl)
        response = await APIHelper.getData(url)
      } else {
        url = CommonHelper.makeApiUrl('v1/resources', {
          content_type: ContentTypes.RESOURCE_MINI_CARD
        })
        response = await APIHelper.getData(url + `?search=1&limit=${payload.limit}`)
      }
      return response
    } catch (e) {
      return e
    }
  }
}

const getters = {
  getUserResourceResources (state) {
    let _contents = state.userResourceContents.filter((item) => {
      return item.content_type === ContentTypes.RESOURCE_MINI_CARD
    })
    _contents = _.sortBy(_contents, function (item) {
      return parseInt(item.order, 10)
    })
    return _contents || []
  },
  getAllResourceContents (state) {
    return state.allResourceContents
  },
  getFarmerStoriesContents (state) {
    return state.farmerStoriesContents
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
