export const IULevel = {
  COUNTRY: {
    identifier: '7ad46894-ceb0-4ad5-8728-afe4290f6efd',
    name: 'Other Countries',
    order: 10,
    parent: null
  },
  NKG_COUNTRY: {
    identifier: '6041dd54-42a4-4e70-b590-f8ca9f4ac601',
    name: 'NKG Country',
    order: 20,
    parent: null
  },
  REGION: {
    identifier: 'fd97ae61-3d68-491f-8d62-602cf85083db',
    name: 'Region',
    order: 30,
    parent: '6041dd54-42a4-4e70-b590-f8ca9f4ac601'
  },
  EXPORTER: {
    identifier: '48a72f3e-bb34-4bc5-9ff8-7f0d6e6172fe',
    name: 'Exporter',
    order: 40,
    parent: '6041dd54-42a4-4e70-b590-f8ca9f4ac601'
  },
  IMPORTER: {
    identifier: 'd00f7e1c-be44-4212-a65c-f00458b7df3c',
    name: 'Importer',
    order: 50,
    parent: '7ad46894-ceb0-4ad5-8728-afe4290f6efd'
  },
  CUSTOMER: {
    identifier: 'c3400edc-d625-4dd7-8dbf-229698a8bf7d',
    name: 'Customer',
    order: 60,
    parent: '7ad46894-ceb0-4ad5-8728-afe4290f6efd'
  },
  GROUP: {
    identifier: 'cea13556-0619-47d6-8bad-ed6a98937de6',
    name: 'Group',
    order: 70,
    parent: 'fd97ae61-3d68-491f-8d62-602cf85083db'
  },
  SUB_GROUP: {
    identifier: '774df815-be4e-49fa-8489-26691c015d7a',
    name: 'Sub Group',
    order: 80,
    parent: 'cea13556-0619-47d6-8bad-ed6a98937de6'
  }
}
