<template>
  <div class="bg-white py-40 mb-30 mt-30">
    <div class="row no-gutters p-lg-0 p-20">
      <div class="col-lg-7 col-md-12 m-auto">
        <div class="form-group">
          <div class="form-row">
            <div class="col-12 col-md-12 col-lg-4 col-sm-12">
              <label class="col-form-label">Current password</label>
            </div>
            <div class="col-12 col-md-12 col-lg-8 col-sm-12">
              <input
                v-model="oldPassword" class="admin-input form-control"
                placeholder="Current password" type="password">
              <div
                v-if="$v.$error & !$v.oldPassword.required"
                class="error">
                Current password is required.
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="form-group">
          <div class="form-row">
            <div class="col-12 col-md-12 col-lg-4 col-sm-12">
              <label class="col-form-label">New password</label>
            </div>
            <div class="col-12 col-md-12 col-lg-8 col-sm-12">
              <input
                v-model="newPassword" class="admin-input form-control"
                placeholder="New password" type="password">
              <div
                v-if="$v.$error & !$v.newPassword.required"
                class="error">
                New password is required.
              </div>
              <div
                v-if="$v.$error & !$v.newPassword.minLength"
                class="error">
                New password must have at least {{ $v.newPassword.$params.minLength.min }} characters.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-row">
            <div class="col-12 col-md-12 col-lg-4 col-sm-12">
              <label class="col-form-label">Confirm password</label>
            </div>
            <div class="col-12 col-md-12 col-lg-8 col-sm-12">
              <input
                v-model="confirmPassword" class="admin-input form-control"
                placeholder="Confirm password" type="password">
              <div
                v-if="$v.$error & !$v.confirmPassword.required"
                class="error">
                Confirm password is required.
              </div>
              <div
                v-if="$v.$error & !$v.confirmPassword.minLength"
                class="error">
                Confirm password must have at least {{ $v.confirmPassword.$params.minLength.min }} characters.
              </div>
              <div
                v-if="$v.$error & !$v.confirmPassword.sameAsPassword"
                class="error">
                Confirm password must be same as new password.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-12 m-auto">
              <div class="text-right mt-30">
                <div class="d-inline-flex">
                  <button class="btn btn-submit w-100" type="button"
                          @click="changePassword"> Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import { minLength, required, sameAs } from 'vuelidate/lib/validators'
import CommonHelper from '@/utils/common'
import APIHelper from '@/service/api-helper'

export default {
  name: 'ChangePassword',
  mixins: [FormMixin, validationMixin],
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  },
  computed: {},
  validations: {
    oldPassword: { required },
    newPassword: { required, minLength: minLength(4) },
    confirmPassword: { required, minLength: minLength(4), sameAsPassword: sameAs('newPassword') }
  },
  methods: {
    changePassword: async function () {
      const vm = this
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          title: '',
          text: 'Please fix error(s) in form.',
          type: 'error',
          duration: 5000
        })
      } else {
        const user = this.$store.getters.getUserInfo
        const userName = user.username
        const url = CommonHelper.makeApiUrl(`change-password/${userName}`)
        const payload = {
          old_password: this.oldPassword,
          password: this.newPassword,
          password2: this.confirmPassword
        }
        try {
          const response = await APIHelper.putData(url, payload)
          const data = response.data
          if (data.success) {
            this.$notify({
              title: 'Password changed',
              text: 'Password changed successfully.',
              type: 'success',
              duration: 5000
            })
            // this.$notify({
            //   title: 'You will logout now. Please login again',
            //   text: '',
            //   type: 'success',
            //   duration: 5000
            // })
            // setTimeout(function () {
            //   vm.$store.dispatch('handleLogout')
            // }, 5000)
            this.oldPassword = ''
            this.newPassword = ''
            this.confirmPassword = ''
            this.$v.$reset()
          } else {
            this.$notify({
              title: '',
              text: 'Password wasn\'t successfully changed.',
              type: 'error',
              duration: 5000
            })
            const oldPasswordWrong = _.get(data, 'old_password.old_password') || ''
            if (oldPasswordWrong) {
              this.$notify({
                title: 'Wrong password',
                text: oldPasswordWrong,
                type: 'error',
                duration: 5000
              })
            }
            const passwordNotMatch = _.get(data, 'password.password') || ''
            if (passwordNotMatch) {
              this.$notify({
                title: '',
                text: passwordNotMatch,
                type: 'error',
                duration: 5000
              })
            }
          }
        } catch (e) {
          return e
        }
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
textarea.form-control {
  padding-top: 15px;
}

</style>
