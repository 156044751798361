import ServerConfig from '../config/server'
import moment from 'moment'
import * as Bowser from 'bowser'
import _ from 'lodash'

export default {
  /* This is a pure VueJS mixin */
  name: 'UtilityMixin',
  data () {
    return {
      orderColumn: 'last_updated', // use for js order
      orderColumnDbRepresentation: 'last_updated', // use for db level order
      orderBy: '-' // - means desc order
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    isAdminUser () {
      return this.$store.getters.iAmAdmin
    },
    isObserverUser () {
      return this.$store.getters.iAmObserver
    },
    years () {
      const years = []
      const currentYears = new Date().getFullYear()
      for (let i = 2020; i <= currentYears; i++) {
        years.push(i)
      }
      return years
    }
  },
  filters: {
    makeAbsUrl (serverRelativePath) {
      /* Use this filter whenever you need to make absolute url for server. */
      const serverUrl = ServerConfig.serverUrl
      // TODO: This is just an hotfix
      if (serverUrl === 'https://admin.trace.nkgbloom.coffee' || serverUrl === 'https://admindemo.portal.nkgbloom.coffee') {
        return `${serverRelativePath}`
      }
      return `${serverUrl}${serverRelativePath}`
    },
    textTruncate (text, length, clamp) {
      clamp = clamp || '...'
      const node = document.createElement('div')
      node.innerHTML = text
      const content = node.textContent
      return content.length > length ? content.slice(0, length) + clamp : content
    },
    smartTextTruncate (text, length, clamp) {
      clamp = clamp || '...'
      return text.substring(0, (text + ' ').lastIndexOf(' ', length)) + clamp
    },
    humanDate (value) {
      if (value === 0) {
        return ''
      }
      return moment(value).format('DD MMMM, YYYY')
    },
    formattedDate (value, format) {
      if (value === 0) {
        return ''
      }
      return moment(value).format(format)
    },
    formatNumberLocale (value) {
      if (typeof value === 'number') {
        return value.toLocaleString()
      }
      return Number(value).toLocaleString()
    },
    shortMonthName (value) {
      return moment(value).format('MMM')
    },
    dataUnitValue (bytes) {
      if (!bytes) return ''
      if (bytes === 0) return '0 Bytes'

      const k = 1024
      const dm = 0
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }
  },
  methods: {
    /**
     * @param {!number} time in microseconds
     */
    async sleep (time) {
      return await new Promise((resolve) => setTimeout(resolve, time))
    },
    /**
     * This function allow you to modify a JS Promise by adding some status properties.
     * Based on: http://stackoverflow.com/questions/21485545/is-there-a-way-to-tell-if-an-es6-promise-is-fulfilled-rejected-resolved
     * But modified according to the specs of promises : https://promisesaplus.com/
     */
    MakeQuerablePromise (promise) {
      // Don't modify any promise that has been already modified.
      if (promise.isResolved) return promise

      // Set initial state
      var isPending = true
      var isRejected = false
      var isFulfilled = false

      // Observe the promise, saving the fulfillment in a closure scope.
      var result = promise.then(
        function (v) {
          isFulfilled = true
          isPending = false
          return v
        },
        function (e) {
          isRejected = true
          isPending = false
          throw e
        }
      )

      result.isFulfilled = function () {
        return isFulfilled
      }
      result.isPending = function () {
        return isPending
      }
      result.isRejected = function () {
        return isRejected
      }
      return result
    },
    uuidV4 () {
      return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      )
    },
    async uploadBase64Photo (photoId, file) {
      let bs64String
      try {
        bs64String = await this.fileToBase64(file)
      } catch (e) {
      }
      const _data = {
        id: photoId,
        name: file.name,
        base64_data: bs64String.split(',')[1]
      }
      return this.$store.dispatch('putBase64Photo', _data)
    },
    async uploadMultipartPhoto (photoId, file) {
      const _data = new FormData()
      _data.append('file', file)
      _data.append('id', photoId)
      _data.append('name', file.name)
      _data.append('extension', _.cloneDeep(file.name).split('.').pop())
      return this.$store.dispatch('putPhotoFile', {
        id: photoId,
        formData: _data
      })
    },
    async uploadFile (fileId, file) {
      const _data = {
        id: fileId
      }
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', file.name)
      formData.append('extension', file.name.split('.').pop())
      _data.formData = formData
      return this.$store.dispatch('putFile', _data)
    },
    fileToBase64: file => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    }),
    getDownloadLink (resource) {
      if (_.get(resource, 'file.name')) {
        const serverRelativePath = resource.file.relative_url
        const serverUrl = ServerConfig.serverUrl

        // TODO: This is just an hotfix
        let _win = ''
        if (serverUrl === 'https://admin.trace.nkgbloom.coffee' || serverUrl === 'https://admindemo.portal.nkgbloom.coffee') {
          _win = window.open(`${serverRelativePath}`)
        } else {
          _win = window.open(`${serverUrl}${serverRelativePath}`)
        }
        _win.focus()
      } else {
        const _win = window.open(resource.more_link)
        _win.focus()
      }
    },
    errorLog (e) {
      let browserMeta = {}
      const error = {}
      browserMeta = Bowser.parse(window.navigator.userAgent)
      const connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection
      browserMeta.connection = {
        connectionType: _.get(connection, 'effectiveType') || '',
        rtt: _.get(connection, 'rtt') || '',
        downlink: _.get(connection, 'downlink') || ''
      }
      error.message = _.get(e, 'data.message') || ''
      error.stacktrace = _.get(e, 'stack') || ''
      error.path_url = window.location.href
      error.meta_info = {
        browser: browserMeta,
        screen: {
          width: _.get(window, 'screen.width') || '',
          height: _.get(window, 'screen.height') || '',
          orientation: _.get(window, 'screen.orientation.type') || ''
        }
      }
      error.organization = this.$store.getters.getOrganization || 1
      error.message = _.get(e, 'message') || ''
      error.url = _.get(e, 'config.url') || ''
      error.request_type = _.get(e, 'config.method') || ''
      error.token = _.get(e, 'config.headers.Authorization') || ''
      error.request_data = _.get(e, 'config.data') || ''
      this.$store.dispatch('logError', error)
    }
  }
}
